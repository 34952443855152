// Init dictionary
var dictionary = window.dictionary || {};

window.getDictionary = function(key) {
    var args = arguments;
    var text = dictionary[key];    
    if (typeof text === "string" && args.length > 1) {
        for(var i=1; i<args.length; i++){ //Start at index 1 because first args is key
            text = text.replace("{" + (i-1) + "}", args[i]);
        }
    }
    if (typeof text !== "string") {
        text = key;
    }
    return text;
}

window.getViewWithReplacedDictionaryItems = function(html) {
    html = html.replace(/{{(.+?)}}/g, function (fullMatchStr,matchStr) {
        if (matchStr.length > 0) {
            var params = matchStr.split("|");
            var dicItem = getDictionary(params[0].trim());
            if (typeof dicItem === "undefined")
                return fullMatchStr;
            if (params.length > 1) {
                for (var i = 1; i < params.length; i++) {
                    var param = params[i].trim();
                    var replStr = param;
                    if (param.length > 2 && param.substring(0, 1) === "'" && param.substring(param.length-1, param.length) === "'") {
                        replStr = param.substring(1, param.length-1);
                    } else {
                        replStr = "{{" + param +"}}";
                    }
                    dicItem = dicItem.replace("{" + (i-1) + "}", replStr);
                }
            }
            dicItem = dicItem.replace(/"/g, "&quot;");
            return dicItem;
        }
        return fullMatchStr;
    });
    return html;
}