﻿moment.locale('sv');

// - - Init login
// $().ready(function () {

//     for (var f = document.forms, i = f.length; i--;) f[i].setAttribute("novalidate", "");
// });

//$(document).on('change', '.shop-orderpart-product select.unit', function () {
//    app.shop.orders.chageProductUnit($(this));
////});

// $(document).on('click', '#shop-order-modal-accept', function (e) {
//     e.preventDefault();
//     window.location = '/webbshop';
// });

//$(document).on('click', '#shop-productSearch-selectedProductGroups .filter', function (e) {
//    e.preventDefault();
//    app.search.removeProductGroup($(this));
//});

// Flyttet til app.search.js
//$(document).on('change', '#shop-productSearch-productGroups', function () {
//    app.search.selectProductGroup($(this));
//});

// Flyttet til app.products.js
//$(document).on('change', '#shop-productList input.amount', function (e) {
//    var $product = $(this).closest('.shop-table-product');
//    $product.addClass('changed');
//});





// - - Init searchfield
// $().ready(function () {
//     var $searchWrapper = $('#shop-productSearch');

//     if ($searchWrapper.length > 0) {
//         $searchWrapper.find('.shop-productSearch-searchText:first').focus();
//     }
// });

// Format webshopsettings / favoritelists
(function (webshopSettings) {
    try {
        if (webshopSettings.favoritelists) {
            for (var i = 0; i < webshopSettings.favoritelists.length; i++) {
                webshopSettings.favoritelists[i].name = Base64.decode(webshopSettings.favoritelists[i].name);
            }
        }
    }
    catch (ex) {

    }
})(webshopSettings);

var app = {
    dashboard: {
        toggleMessage: function (event) {
            event.preventDefault();

            var $this = $(this),
                $messageWrapper = $this.closest('.shop-dashboard-messages'),
                $messages = $messageWrapper.find('.ui-message').not('.ui-message-fadeout');

            if ($messages.length <= 1) {
                setTimeout(function () {
                    $messageWrapper.addClass('hidden');
                }, 300)
            }
        }
    },

    //favorites: {
        //activeFavoriteList: null,

        // addProduct: function (event) {
        //     event.preventDefault();

        //     var $form = $(this),
        //         $productId = $form.find('input[name="productId"]'),
        //         //listName = decodeURIComponent(app.helpers.requestQueryString().favoritelist),
        //         listId = app.helpers.requestQueryString().favoritelist,
        //         settings = {
        //             clear: true
        //         };

        //     var data = {
        //         id: Number(listId),
        //         //listName: unescape(listName),
        //         productId: $productId.val(),
        //         stdUnit: 'std'
        //     };

        //     app.services.favorites.addProduct(data).then(function (response) {
        //         if (response.d.result != null) {
        //             app.search.currentSearchResults = response.d.result.search;
        //             if (app.helpers.cookies.read('searchResultType') === 'gallery') {
        //                 app.search.searchResults.galleryView($('#shop-productList-gallery'), settings);
        //             }
        //             else {
        //                 app.search.searchResults.listView($('#shop-productList').find('tbody'), settings);
        //             }

        //             $.dcui.toast(getDictionary('webshop_search_favorite_toggleFavorite_addProduct_toast_added', response.d.result.productName), 'success');
        //             $productId.val('');
        //         }
        //         else {
        //             var errorMessage = getDictionary('webshop_search_favorite_toggleFavorite_addProduct_toast_error');
        //             if (response.d.statusCode === 400) {
        //                 errorMessage = getDictionary('webshop_search_favorite_toggleFavorite_addProduct_toast_listNotFound');
        //             }
        //             else if (response.d.statusCode === 404) {
        //                 errorMessage = getDictionary('webshop_search_favorite_toggleFavorite_addProduct_toast_productNotexists');
        //             }
        //             else if (response.d.statusCode === 409) {
        //                 errorMessage = getDictionary('webshop_search_favorite_toggleFavorite_addProduct_toast_exists');
        //             }
        //             $.dcui.toast(errorMessage, 'error');

        //             $productId.focus().select();
        //         }
        //     },
        //         function () {
        //             $.dcui.toast(getDictionary('webshop_search_favorite_toggleFavorite_addProduct_toast_error'), 'error');
        //         });

        //     //appServices.favorites.addProduct(ajaxData, ajaxSuccess, ajaxFail);
        //     app.log.event('Webshop', 'Favorites', 'Add product');
        // },

        // toggleAddProduct: function (event) {
        //     if (event) {
        //         event.preventDefault();
        //     }

        //     var $this = $(this),
        //         $wrapper = $('#shop-productSearch-favoriteListAddProduct-wrapper');

        //     if ($wrapper.hasClass('shop-productSearch-favoriteListAddProduct-wrapper-visible')) {
        //         $wrapper.removeClass('shop-productSearch-favoriteListAddProduct-wrapper-visible');
        //     }
        //     else {
        //         $wrapper.addClass('shop-productSearch-favoriteListAddProduct-wrapper-visible');
        //         $wrapper.find('input[name="productId"]').focus();
        //     }
        // },

        // toggleDownloadCsv: function (event) {
        //     event.preventDefault();

        //     app.log.event('Webshop', 'Favorites', 'Download as csv');

        //     window.location = '/favoritelistexport?listid=' + app.helpers.requestQueryString().favoritelist;


        //     //if (app.favorites.activeFavoriteList) {
        //     //    window.location = '/favoritelistexport?listid=' + app.favorites.activeFavoriteList;
        //     //}
        // },

        // print: function (event) {
        //     event.preventDefault();

        //     app.log.event('Webshop', 'Favorites', 'Print');

        //     window.open('/printfavoritelist?favoritelist=' + app.helpers.requestQueryString().favoritelist);
        // },

        // setSortOrder: function (event) {
        //     //if (event) {
        //     //    event.preventDefault();
        //     //}

        //     var $this = $(this),
        //         value = $this.val(),
        //         listId = app.helpers.requestQueryString().favoritelist,
        //         $product = $this.closest('.shop-table-product'),
        //         isAltProduct = $product.hasClass('alternativ-product'),
        //         productId = $product.attr('data-id');

        //     if (isAltProduct) {
        //         if ($product.find('.shop-product-addToFavorites.shop-product-addToFavorites-active').length === 0) {
        //             $.dcui.toast(getDictionary('webshop_search_favorite_setSortOrder_toast_alternativProduct'), 'error');
        //             return;
        //         }
        //     }

        //     var json = {
        //         id: Number(listId),
        //         productId: productId,
        //         sortOrder: value
        //     };

        //     app.log.event('Webshop', 'Favorites', 'Set sort order: ' + value);

        //     app.services.favorites.setSortOrder(json).then(function (response) {
        //         if (response.d.success == true) {
        //             $.dcui.toast(getDictionary('webshop_search_favorite_setSortOrder_toast_edited', productId), 'success');
        //         }
        //         else {
        //             $.dcui.toast(getDictionary('webshop_search_favorite_setSortOrder_toast_error'), 'error');
        //         }
        //     }, function (response) {
        //         $.dcui.toast(getDictionary('webshop_search_favorite_setSortOrder_toast_error'), 'error');
        //     })

        //     //appServices.favorites.setSortOrder(ajaxData, ajaxSuccess, ajaxFail);
        // },

        //toggleEdit -> app.favorites.js

        // toggleFavoriteFromThumb: function (event) {
        //     if (event) {
        //         event.preventDefault();
        //     }


        // }
    //},

    // foodinfo: function (event) {
    //     var $this = $(this),
    //         url = $this.attr('data-foodinfo-ref'),
    //         data = { url: url };

    //     app.log.event('Webshop', 'Show foodinfo');

    //     if ($this.hasClass('shop-foodinfo-modal')) {
    //         event.preventDefault();

    //         $.dcui.modal({
    //             content: '<div id="foodinfo"></div>',
    //             onInit: function () {
    //                 app.services.foodinfo.request(data).then(function (response) {
    //                     console.log(response);
    //                     $('#foodinfo').html(response);
    //                 }, function (response) {
    //                     console.log(JSON.stringify(response));
    //                     $.dcui.modal.close();
    //                     $.dcui.toast('Der er sket en fejl i indlæsningen af foodinfo', 'error');
    //                 });
    //             },
    //             width: '1000px',
    //             height: '800px'
    //         });
    //     }
    // },

    // foodinfoImage: {
    //     show: function (event) {

    //         var $this = $(this),
    //             $container = $this.closest('.shop-product-table-icons'),
    //             $image = $container.find('.shop-product-table-image');

    //         $image.html('<img src="' + $image.attr('data-image-src') + '"/>');
    //         $container.addClass('show-image');
    //     },
    //     hide: function (event) {
    //         var $this = $(this),
    //             $container = $this.closest('.shop-product-table-icons');

    //         $container.removeClass('show-image');
    //     }
    // },

    forms: {
        validate: function ($form, success, fail) {
            var valid = true;
            var invalidElements = new Array();

            $form.find('input:not([type="submit"]), textarea, select').each(function (i, input) {
                var $input = $(input);
                var inputValid = true;

                if ($input.attr('novalidation') != 'novalidation') {
                    if ($input.attr('required') == 'required') {
                        if ($input.attr('data-type') !== undefined && $input.attr('data-type') != "" && $input.attr('data-type') != "text" && $input.attr('data-type') != "password") {
                            inputValid = app.forms.validation[$input.attr('data-type')]($input) && app.forms.validation.text($input);
                        } else {
                            inputValid = app.forms.validation.text($input);
                        }
                    } else {
                        if ($input.val() != '' || ($input.attr('matches') !== undefined && $input.attr('matches') != '')) {
                            if ($input.attr('data-type') !== undefined && $input.attr('data-type') != "" && $input.attr('data-type') != "text") {
                                inputValid = app.forms.validation[$input.attr('data-type')]($input) && app.forms.validation.text($input);
                            } else {
                                inputValid = app.forms.validation.text($input);
                            }
                        }
                    }
                }

                if (!inputValid) {
                    valid = false;
                    invalidElements.push($input);
                }
            });

            if (valid) {
                success();
            } else {
                fail(invalidElements);
            }
        },

        validation: {
            email: function ($input) {
                var regExp = new RegExp('^[a-zA-Z0-9]+([\.\-\_][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([\.\-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$');
                if (regExp.test($input.val())) {
                    return true;
                }
                return false;
            },

            number: function ($input) {
                if ($input.val() == "" || isNaN($input.val())) {
                    return false;
                } else {
                    var val = parseFloat($input.val());
                    if ($input.attr('min') !== undefined && $input.attr('min') != "" && !isNaN($input.attr('min'))) {
                        var min = parseFloat($input.attr('min'));
                        if (val < min) {
                            return false;
                        }
                    }
                    if ($input.attr('max') !== undefined && $input.attr('max') != "" && !isNaN($input.attr('max'))) {
                        var max = parseFloat($input.attr('max'));
                        if (val > max) {
                            return false;
                        }
                    }
                }
                return true;
            },

            date: function ($input) {
                var valid = true;
                var inputDate;
                var format = false;

                if (valid && $input.attr('format') !== undefined && $input.attr('format') != "") {
                    try {
                        format = $input.attr('format');
                    } catch (ex) {
                        valid = false;
                    }
                }

                if (valid) {
                    try {
                        if (!format) {
                            inputDate = moment($input.val());
                        } else {
                            inputDate = moment($input.val(), format, true);
                        }
                        if (!inputDate.isValid()) {
                            valid = false;
                        }
                    } catch (ex) {
                        valid = false;
                    }
                }

                if (valid && $input.attr('min') !== undefined && $input.attr('min') != "") {
                    try {
                        var minDate;

                        if (!format) {
                            minDate = moment($input.attr('min'));
                        } else {
                            minDate = moment($input.attr('min'), format, true);
                        }
                        if (minDate.isValid()) {
                            valid = !inputDate.isBefore(minDate);
                        } else {
                            valid = false;
                        }
                    } catch (ex) {
                        valid = false;
                    }
                }

                if (valid && $input.attr('max') !== undefined && $input.attr('max') != "") {
                    try {
                        var maxDate;

                        if (!format) {
                            maxDate = moment($input.attr('max'));
                        } else {
                            maxDate = moment($input.attr('max'), format, true);
                        }
                        if (maxDate.isValid(true)) {
                            valid = !inputDate.isAfter(minDate);
                        } else {
                            valid = false;
                        }
                    } catch (ex) {
                        valid = false;
                    }
                }

                return valid;
            },

            datetime: function ($input) {
                return app.forms.validation.date($input);
            },

            text: function ($input) {
                if ($input.val() != '') {
                    var valid = true;

                    if ($input.attr('pattern') !== undefined && $input.attr('pattern') != "") {
                        try {
                            var regExp = new RegExp($input.attr('pattern'));
                            valid = regExp.test($input.val());
                        } catch (ex) {
                            valid = false;
                        }
                    }

                    if ($input.attr('min-length') !== undefined && $input.attr('min-length') != "" && !isNaN($input.attr('min-length'))) {
                        var minLength = parseInt($input.attr('min-length'));
                        if ($input.val().length < minLength) {
                            valid = false;
                        }
                    }

                    if ($input.attr('max-length') !== undefined && $input.attr('max-length') != "" && !isNaN($input.attr('max-length'))) {
                        var maxLength = parseInt($input.attr('max-length'));
                        if ($input.val().length > maxLength) {
                            valid = false;
                        }
                    }
                    if ($input.attr('matches') !== undefined && $input.attr('matches') != "") {
                        var $matchingInput = $input.closest('form').find('input[name="' + $input.attr('matches') + '"], select[name="' + $input.attr('matches') + '"], textarea[name="' + $input.attr('matches') + '"]');

                        if ($matchingInput.val() != $input.val()) {
                            valid = false;
                        }
                    }

                    return valid;
                }

                return false;
            }
        }
    },

    frontpage: {
        slideshow: {
            toggleSlide: function (event) {
                event.preventDefault();

                var $this = $(this),
                    $list = $this.closest('#frontpage-newsWrapper-newsList'),
                    $listItems = $list.find('.news-item'),
                    $newsItemWrapper = $('#frontpage-newsWrapper-newsArea'),
                    $newsItems = $newsItemWrapper.find('.frontpage-newsWrapper-newsItem'),
                    targetNewsId = $this.attr('data-newsid'),
                    $targetNewsItem = $newsItemWrapper.find('li[data-newsid="' + targetNewsId + '"]');


                $listItems.removeClass('news-item-active');
                $newsItems.removeClass('frontpage-newsWrapper-newsItem-active');

                $targetNewsItem.attr('style', 'background-image: url("' + $targetNewsItem.attr('data-image') + '")');
                $targetNewsItem.addClass('frontpage-newsWrapper-newsItem-active');
                $this.addClass('news-item-active');

                //frontpage-newsWrapper-newsArea
                //frontpage-newsWrapper-newsList
                //alert('wat: ' + targetNewsId);
            }
        }
    },

    helpers: {
        cookies: {
            create: function (name, value, days) {
                var expires;
                if (days) {
                    var date = new Date();
                    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toGMTString();
                }
                else {
                    expires = "";
                }
                document.cookie = name + "=" + value + expires + "; path=/";
            },

            read: function (name) {
                var nameEQ = name + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) === ' ') {
                        c = c.substring(1, c.length);
                    }
                    if (c.indexOf(nameEQ) === 0) {
                        return c.substring(nameEQ.length, c.length);
                    }
                }
                return null;
            }
        },

        detectIe: function () {
            var undef,
                v = 3,
                div = document.createElement('div'),
                all = div.getElementsByTagName('i');

            while (
                div.innerHTML = '<!--[if gt IE ' + (++v) + ']><i></i><![endif]-->',
                all[0]
            );

            return v > 4 ? v : undef;
        },

        elementInViewport: function (el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top >= window.pageYOffset &&
                left >= window.pageXOffset &&
                (top + height) <= (window.pageYOffset + window.innerHeight) &&
                (left + width) <= (window.pageXOffset + window.innerWidth)
            );
        },

        isNumber: function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n) && n.toString().indexOf('.') == -1;
        },

        isEmail: function (email) {
            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            return re.test(email);
        },

        requestQueryString: function () {
            var query_string = {};
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                // If first entry with this name
                if (typeof query_string[pair[0]] === "undefined") {
                    query_string[pair[0]] = pair[1];
                    // If second entry with this name
                } else if (typeof query_string[pair[0]] === "string") {
                    var arr = [query_string[pair[0]], pair[1]];
                    query_string[pair[0]] = arr;
                    // If third or later entry with this name
                } else {
                    query_string[pair[0]].push(pair[1]);
                }
            }
            return query_string;
        },

        supportsLocalStorage: function () {
            var value = "value";
            try {
                localStorage.setItem(value, value);
                localStorage.removeItem(value);
                return true;
            } catch (e) {
                return false;
            }
        }
    },

    // info: {
    //     productSheet: function (event) {
    //         event.preventDefault();

    //         app.log.event('Webshop', 'Show product sheet');

    //         var $this = $(this),
    //             url = $this.attr('data-datablad-ref');

    //         window.open(url);
    //     }
    // },

    // inspiration: {
    //     getSearchInspiration: function ($container) {
    //         if ($container) {
    //             var customerGroupCode = app.settings && app.settings.customerGroupCode ? app.settings.customerGroupCode : null,
    //                 showRandom = $container.attr('data-random') === 'true',
    //                 amount = $container.attr('data-amount') && typeof (parseInt($container.attr('data-amount'))) == 'number' && !isNaN(parseInt($container.attr('data-amount'))) ? parseInt($container.attr('data-amount')) : null,
    //                 success = function (view, data) {

    //                 }

    //             if (customerGroupCode) {
    //                 $.when(
    //                     app.services.views.getView('shopSearchInspirationItem'),
    //                     app.services.inspiration.getInspirationNodesByCode(customerGroupCode)).then(function (view, data) {
    //                         var $view = $(view[0]);
    //                         if (data) {
    //                             var result = data[0].sort(function () { return 0.5 - Math.random() }),
    //                                 htmlResult = '';

    //                             if (amount != null) {
    //                                 result = result.slice(0, amount);
    //                             }

    //                             for (var i = 0; i < result.length; i++) {
    //                                 var inspiration = result[i],
    //                                     $inspiration = $view.clone();

    //                                 $inspiration.attr({
    //                                     'data-productgroups': inspiration.ProductGroups,
    //                                     'data-filter-offers': inspiration.FilterOffers,
    //                                     'data-filter-wholegrain': inspiration.FilterWholegrain,
    //                                     'data-filter-keyhole': inspiration.FilterKeyhole,
    //                                     'data-filter-organic': inspiration.FilterOrganic,
    //                                     'data-filter-halal': inspiration.FilterHalal,
    //                                     'data-filter-frost': inspiration.FilterFrost,
    //                                     'data-filter-cool': inspiration.FilterCool,
    //                                     'data-filter-procure': inspiration.FilterProcure,
    //                                     'data-id': inspiration.Id,
    //                                     'data-searchtext': inspiration.SearchText,
    //                                     'style': "background-image:url('" + inspiration.Image + "')",
    //                                     'title': inspiration.Title
    //                                 });

    //                                 htmlResult += $inspiration.wrap('<p>').parent().html();
    //                             }

    //                             $container.html(htmlResult);
    //                         }
    //                     }, function (viewErr, dataErr) {
    //                         app.log.error('view & inpiration error', { viewErr: viewErr, dataErr: dataErr });
    //                     }
    //                     );
    //             }
    //             else {
    //                 app.log.error('inspiration.getSearchInspiration error', 'app.settings.customerGroupCode = 0', { appSettings: app.settings }, 'custom');
    //             }
    //         }
    //     }
    // },

    isMobile: function () {
        if (app.helpers.supportsLocalStorage()) {
            if (typeof window.innerWidth != 'undefined' && window.innerWidth <= 720) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }

    },

    log: {
        event: function (category, action, label) {
            var event = {
                hitType: 'event'
            };

            event.eventCategory = category ? category : "";
            event.eventAction = action ? action : "";
            event.eventLabel = label ? label : "";

            if (typeof ga != 'undefined') {
                ga('send', event);
            }

            console.log('logged: ', event);
        },

        error: function (message, error, data, status) {
            appErrorLog(message, error, data, status);
        }
    },

    login: {
        handleLogout: function (event) {
            event.preventDefault();

            var $minicart = $('.shop-minicart'),
                currentItemsCount = $minicart.length > 0 && $($minicart[0]).attr('data-itemcount') ? $($minicart[0]).attr('data-itemcount') : 0;

            if (currentItemsCount > 0) {
                $.dcui.modal({
                    headerContent: getDictionary('webshop_modals_logout_header'),
                    content: '<p>' + getDictionary('webshop_modals_logout_content') + '</p>',
                    confirm: {
                        onConfirm: function () {
                            $.dcui.modal.close();
                            window.location = app.settings.paths.cart;
                        },
                        onClose: function () {
                            console.log('wat');
                            window.location = '?logout=true';
                        }
                    },
                    cancelText: getDictionary('webshop_modals_logout_buttonLogout'),
                    acceptText: getDictionary('webshop_modals_logout_goToBasket'),
                    disableClose: true,
                    disableOverlayClose: true
                })
            }
            else {
                app.log.event('Webshop', 'Logout');
                window.location = '?logout=true';
            }
        },
    },

    mailchimp: {
        callback: function (response) {
            console.log(response, [response.msg.indexOf('1 -')]);
            if (response.result == 'error' && response.msg.indexOf('1 -') == -1) {
                $('#mce-responses').html(response.msg).removeClass('hidden');
            }
            else if (response.result == 'error') {
                $('#mce-responses').removeClass('hidden');
            }
            else if (response.result == 'success') {
                $.dcui.modal({
                    headerContent: 'Nyhedsbrev tilmelding',
                    content: "<p>" + response.msg + "</p>",
                    footerContent: '<div class="ui-form-submitwrapper"><a class="ui-button ui-modal-closer">Ok</a></div>'
                });

                $('.mailchimp-signup').find('input[type="text"]').val('');
            }
        },

        handleSubmit: function (event) {
            if (event) {
                event.preventDefault();
            }

            var $form = $(this),
                url = $form.attr('action'),
                server = $form.attr('data-server'),
                listId = $form.attr('data-list-id'),
                id = $form.attr('data-id'),
                apiUrl = 'https://us2.api.mailchimp.com/3.0/lists/' + listId + '/members/';

            $('#mce-responses').addClass('hidden');

            $.ajax({
                type: 'POST',
                url: url,
                dataType: 'jsonp',
                data: $form.serialize()
            }).then(function (response) {
                console.log('success', response);
            }).fail(function (response) {
                console.log('fail', response);
                //$.dcui.toast(response.msg, 'error');
                //app.log.error('Fejl i mailchimp tilmelding', { url: url, data: $form.serialize() });
            });

            app.log.event('Webshop', 'Newsletter signup');
        }
    },

    navigation: {
        toggleResponsiveNavigation: function (event) {
            event.preventDefault();

            var $this = $(this),
                $navigation = $('#top-navigation');

            if ($navigation.hasClass('top-navigation-active')) {
                $navigation.removeClass('top-navigation-active');
            }
            else {
                $navigation.addClass('top-navigation-active');
            }

        }
    },

    // offers: {
    //     toggleDepartmentOfferPaper: function (event) {
    //         event.preventDefault();

    //         var $this = $(this),
    //             $form = $this.closest('#form-ipaperOffers'),
    //             $button = $form.find('.shop-ipaperOffers-download'),
    //             paperPath = $this.val();

    //         $button.attr({
    //             'href': paperPath,
    //             'data-department': $this.find('option:selected').attr('data-department')
    //         });
    //     },

    //     downloadDepartmentOfferPaper: function (event) {
    //         var $this = $(this);

    //         if ($this.attr('href').length == 0 || $this.attr('href') == "#") {
    //             event.preventDefault();

    //             $.dcui.toast('Du skal vælge en afdeling, før du kan hente tilbudsavisen', 'error');
    //         }
    //         app.log.event('Webshop', 'Download', 'Department offer');

    //         app.helpers.cookies.create('offerDownloadDepartment', $this.attr('data-department'), 364);
    //     }
    // },

    shop: {

        // Deprecated?
        // addToBasket: function (products) {
        //     var successCallback = function (response) {
        //         console.log('addToBasket', response);
        //         if (!(response.d != null && response.d.addedOrderlines > 0)) {
        //         	$.dcui.toast('Der sket en fejl og der blev ikke tilføjet noget til kurven', 'error', 'Fejl');
        //         }
        //     }

        //     app.shop.cart.addToCart(products, successCallback, function () {
        //         $.dcui.toast('Der er sket en fejl', 'error');
        //     });
        // },

        // addSingleProductToBasketFromList: function (event) {
        //     if (event) {
        //         event.preventDefault();
        //     }

        //     console.log('addSingleProductToBasketFromList');

        //     var $this = $(this),
        //         $product = $this.closest('.shop-table-product'),
        //         $unit = $product.find('select.unit'),
        //         unit = $product.attr('data-unit'),
        //         productId = $product.attr('data-id'),
        //         $quantity = $product.find('.quantity'),
        //         expiryRef = $product.attr('data-expiry'),
        //         $button = $product.find('.shop-product-addToBasket');
        //     try {
        //         if ($quantity.length > 0 && $quantity.val().length > 0 && $quantity.val() != 0) {
        //             $quantity.attr('disabled', 'disabled');
        //             $unit.attr('disabled', 'disabled');
        //             $product.addClass('shop-product-loading');

        //             var products = [{ amount: $quantity.val(), productId: productId, unit: unit, expiryRef: expiryRef }],
        //                 successCallback = function (response) {
        //                     if (response.d != null && response.d.addedOrderlines > 0) {
        //                         $quantity.val('');
        //                         $product.addClass('shop-product-loading-success shop-product-inBasket');
        //                         $quantity.removeAttr('disabled');
        //                         $unit.removeAttr('disabled');
        //                         $product.removeClass('shop-product-loading');

        //                         setTimeout(function () {
        //                             $product.removeClass('shop-product-loading-success');
        //                         }, 2000);
        //                     }
        //                     else {
        //                         $quantity.removeAttr('disabled');
        //                         $unit.removeAttr('disabled');
        //                         $product.removeClass('shop-product-loading');
        //                         $product.addClass('shop-product-loading-error');

        //                         setTimeout(function () {
        //                             $product.removeClass('shop-product-loading-error');
        //                         }, 2000);

        //                         var errorMessage = getDictionary('webshop_cart_add_toast_errorText');
        //                         if (response.d.statusCode === 416) {
        //                             var stockWarning = JSON.parse(response.d.error);
        //                             $product.find('.expiry-stock').text(stockWarning.stock);

        //                             var expiryDate = new Date(stockWarning.expiryDate);
        //                             var deliveryDate = new Date(stockWarning.deliveryDate);
        //                             if (stockWarning.expiryDate && expiryDate >= deliveryDate) {
        //                                 var inBasket = $product.hasClass('shop-product-inBasket');
        //                                 if (stockWarning.stock === 0) {
        //                                     errorMessage = getDictionary('webshop_cart_add_toast_expiry_stockZero', stockWarning.productId);
        //                                 } else if (inBasket) {
        //                                     errorMessage = getDictionary('webshop_cart_add_toast_expiry_amountLimitReached_inBasket', stockWarning.productId);
        //                                 } else if (stockWarning.stock < stockWarning.amount) {
        //                                     errorMessage = getDictionary('webshop_cart_add_toast_expiry_amountLimitReached', stockWarning.productId, stockWarning.stock);
        //                                 }
        //                             }
        //                         }
        //                         $.dcui.toast(errorMessage, 'error', getDictionary('webshop_cart_add_toast_errorHeader'));
        //                     }
        //                 },
        //                 errorCallback = function () {

        //                     $quantity.removeAttr('disabled');
        //                     $unit.removeAttr('disabled');
        //                     $product.removeClass('shop-product-loading');

        //                     $.dcui.toast(getDictionary('webshop_defaults_toast_errorText'), 'error');
        //                 };

        //             app.shop.cart.addToCart(products, successCallback, errorCallback);
        //         }
        //         else {
        //             $quantity.focus();
        //             $.dcui.toast(getDictionary('webshop_cart_add_toast_missingAmount'), 'error');
        //         }
        //     }
        //     catch (ex) {
        //         app.log.error('addSingleProductToBasketFromList: error', ex);
        //     }

        //     app.log.event('Webshop', 'Search', 'List: Add single item on click');

        //     //$button.addClass('disabled');

        // },

        // addSingleProductToBasket: function (event) {
        //     event.preventDefault();

        //     console.log('addSingleProductToBasket');

        //     var $this = $(this),
        //         $product = $this.closest('.shop-table-product'),
        //         unit = $product.attr('data-unit'),
        //         productId = $product.attr('data-id'),
        //         $quantity = $product.find('.shop-product-thumb-quantity'),
        //         expiryRef = $product.attr('data-expiry'),
        //         $button = $product.find('.shop-product-addToBasket');

        //     if ($quantity.val() && $quantity.val().length > 0 && $quantity.val() != 0) {
        //         $quantity.attr('disabled', 'disabled');
        //         $button.attr('disabled', 'disabled');
        //         $product.addClass('shop-product-loading');

        //         var products = [{ amount: $quantity.val(), productId: productId, unit: unit, expiryRef: expiryRef }],
        //             successCallback = function (response) {
        //                 if (response.d != null && response.d.addedOrderlines > 0) {
        //                     $quantity.val('');
        //                     $product.addClass('shop-product-loading-success shop-product-inBasket');
        //                     $quantity.removeAttr('disabled');
        //                     $button.removeAttr('disabled');
        //                     $product.removeClass('shop-product-loading');

        //                     setTimeout(function () {
        //                         $product.removeClass('shop-product-loading-success');
        //                     }, 2000);
        //                 }
        //                 else {
        //                     $quantity.removeAttr('disabled');
        //                     $button.removeAttr('disabled');
        //                     $product.removeClass('shop-product-loading');
        //                     $product.addClass('shop-product-loading-error');

        //                     setTimeout(function () {
        //                         $product.removeClass('shop-product-loading-error');
        //                     }, 2000);

        //                     var errorMessage = getDictionary('webshop_cart_add_toast_errorText');
        //                     if (response.d.statusCode === 416) {
        //                         var stockWarning = JSON.parse(response.d.error);
        //                         $product.find('.expiry-stock').text(stockWarning.stock);

        //                         var expiryDate = new Date(stockWarning.expiryDate);
        //                         var deliveryDate = new Date(stockWarning.deliveryDate);
        //                         if (stockWarning.expiryDate && expiryDate >= deliveryDate) {
        //                             var inBasket = $product.hasClass('shop-product-inBasket');
        //                             if (stockWarning.stock === 0) {
        //                                 errorMessage = getDictionary('webshop_cart_add_toast_expiry_stockZero', stockWarning.productId);
        //                             } else if (inBasket) {
        //                                 errorMessage = getDictionary('webshop_cart_add_toast_expiry_amountLimitReached_inBasket', stockWarning.productId);
        //                             } else if (stockWarning.stock < stockWarning.amount) {
        //                                 errorMessage = getDictionary('webshop_cart_add_toast_expiry_amountLimitReached', stockWarning.productId, stockWarning.stock);
        //                             }
        //                         }
        //                     }
        //                     $.dcui.toast(errorMessage, 'error', getDictionary('webshop_cart_add_toast_errorHeader'));
        //                 }
        //             },
        //             errorCallback = function () {

        //                 $quantity.removeAttr('disabled');
        //                 $button.removeAttr('disabled');
        //                 $product.removeClass('shop-product-loading');

        //                 $.dcui.toast(getDictionary('webshop_defaults_toast_errorText'), 'error');
        //             };

        //         app.shop.cart.addToCart(products, successCallback, errorCallback);
        //     }
        //     else {
        //         $quantity.focus();
        //         $.dcui.toast(getDictionary('webshop_cart_add_toast_missingAmount'), 'error');
        //     }
        //     app.log.event('Webshop', 'Search', 'Thumb: Add single item on click');
        // },

        // addSingleProductToBasketOnKey: function (event) {
        //     if (event.which == 13) {
        //         var $this = $(this),
        //             $button = $this.closest('.shop-product-thumb').find('.shop-product-addToBasket');

        //         $button.click();

        //         app.log.event('Webshop', 'Search', 'Thumb: Add single item on enter');

        //         event.stopPropagation();
        //     }
        // },

        // cart: {
        //     addAlternativeProductToCart: function ($product) {
        //         var products = [
        //             {
        //                 amount: 1,
        //                 productId: $product.next().attr('data-id'),
        //                 unit: $product.next().attr('data-broachunit')
        //             }
        //         ];

        //         app.shop.cart.addToCart(products, function () {
        //             app.shop.orders.removeProduct($product);
        //         }, function () { $.dcui.toast(getDictionary('webshop_cart_add_toast_basketNotUpdated'), 'error'); });
        //     },


        //     addToCart: function (products, callback, errorCallback) {
        //         var json = {
        //             input: products
        //         }
        //         /* products format: 
        //         [
        //             { amount: xxx, productId: xxx, unit: xxx }
        //         ]
        //         */
        //         console.log('---', products);

        //         if (products && products.length > 0) {

        //             app.services.cart.addToOrder(products).then(function (response) {
        //                 if (response.d != null && response.d.addedOrderlines > 0) {
        //                     $.dcui.toast(getDictionary('webshop_cart_add_toast_basketAdded', '<i>' + response.d.addedOrderlines + '</i>'), 'success');
        //                     app.shop.cart.updateMiniCart({
        //                         count: response.d.PreviewOrder.itemCount,
        //                         organicCount: response.d.PreviewOrder.ecoPct,
        //                         agreementCount: response.d.PreviewOrder.agreementPct,
        //                         noOfTooLate: response.d.PreviewOrder.noOfTooLate,
        //                         noOfExpired: response.d.PreviewOrder.noOfExpired
        //                     });
        //                 }

        //                 if (callback) {
        //                     callback(response);
        //                 }
        //                 else {
        //                     $.dcui.toast(getDictionary('webshop_cart_add_toast_basketUpdated'), 'success');
        //                 }
        //             }, function (response) {
        //                 if (errorCallback) {
        //                     errorCallback(response);
        //                 }
        //                 else {
        //                     $.dcui.toast(getDictionary('webshop_cart_add_toast_basketNotUpdated'), 'error');
        //                 }
        //             });



        //             //appServices.cart.addToOrder(ajaxData, ajaxSuccess, ajaxFail);
        //             //$.ajax({
        //             //    type: "POST",
        //             //    url: url,
        //             //    data: '{"input":' + JSON.stringify(products) + '}',
        //             //    contentType: "application/json; charset=utf-8",
        //             //    dataType: "json"
        //             //}).done(function (response) {
        //             //    // Check if user is logged in
        //             //    if (callback) {
        //             //        ajaxSuccessCallback(response);

        //             //    }
        //             //    else {
        //             //        window.location.reload();
        //             //    }

        //             //}).fail(function (response) {
        //             //    errorCallback(response);
        //             //});
        //         }
        //         else {
        //             $.dcui.toast(getDictionary('webshop_cart_add_toast_errorNoProducts'), 'error');
        //         }
        //     },

        //     emptyCart: function (event) {
        //         event.preventDefault();
        //         var date = $(this).attr('data-date'),
        //             $orderpart = $(this).closest('.shop-orderpart-wrapper'),
        //             json = { date: date.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") };

        //         $.dcui.modal({
        //             headerContent: getDictionary('webshop_cart_empty_modalHeader'),
        //             content: '<p>' + getDictionary('webshop_cart_empty_modalText', date) + '</p>',
        //             isConfirm: true,
        //             confirm: {
        //                 onConfirm: function () {
        //                     app.services.cart.empty(json).then(function (response) {
        //                         try {
        //                             $orderpart.addClass('shop-orderpart-wrapper-remove');
        //                             setTimeout(function () {
        //                                 $orderpart.remove();
        //                                 app.shop.orders.checkExpiryProductDisclaimer();
        //                             }, 400);

        //                             if (!response) {
        //                                 app.log.error('emptyCart error', response);
        //                             }

        //                             app.shop.cart.updateMiniCart({
        //                                 count: response.itemCount,
        //                                 organicCount: response.ecoPct,
        //                                 agreementCount: response.agreementPct,
        //                                 noOfTooLate: response.noOfTooLate,
        //                                 noOfExpired: response.noOfExpired
        //                             });


        //                             $.dcui.modal({
        //                                 headerContent: getDictionary('webshop_cart_empty_successHeader'),
        //                                 content: '<p>' + getDictionary('webshop_cart_empty_successText', date) + '</p>',
        //                                 footerContent: '<div class="ui-form-submitwrapper"><a class="ui-button ui-modal-closer">' + getDictionary('webshop_cart_empty_successClose') + '</a></div>'
        //                             });
        //                         }
        //                         catch (ex) {
        //                             app.log.error('itemCount-error: emptyCart', { response: response, exception: ex });
        //                         }
        //                     }, function () {
        //                         $.dcui.modal({
        //                             headerContent: getDictionary('webshop_cart_empty_errorHeader'),
        //                             content: '<p>' + getDictionary('webshop_cart_empty_errorText') + '</p>'
        //                         });
        //                     });
        //                 }
        //             },
        //             acceptText: getDictionary('webshop_cart_empty_modalButtonAccept'),
        //             cancelText: getDictionary('webshop_cart_empty_modalButtonCancel')
        //         });
        //     },

        //     updateMiniCart: function (obj) {
        //         var $miniCart = $('.shop-minicart'),
        //             $count = $miniCart.find('.shop-minicart-count'),
        //             $organicCount = $('.shop-organicPercentage-number'),
        //             $agreementCount = $('.shop-agreementPercentage-number'),
        //             $delayMessage = $('.shop-minicart-delayMessage'),
        //             $noOfTooLate = $delayMessage.find('.no-of-too-late');

        //         if (obj) {
        //             console.log(obj);
        //             $miniCart.attr('data-itemcount', obj.count),
        //                 $count.html(obj.count);
        //             $organicCount.html(obj.organicCount + ' %');
        //             $agreementCount.html(obj.agreementCount + ' %');
        //             if (obj.noOfTooLate !== 'undefined') {
        //                 $noOfTooLate.text(obj.noOfTooLate);
        //                 if (obj.noOfTooLate < 1) {
        //                     $delayMessage.hide();
        //                 } else {
        //                     $delayMessage.show();
        //                 }
        //             }
        //         }
        //     }
        // },

        header: {
            toggleUserMenu: function (event) {
                if (event) {
                    event.preventDefault();
                }

                var $this = $(this),
                    $wrapper = $this.closest('.ui-menu-wrapper');

                var items = []
                items.push({
                    name: getDictionary('webshop_layout_header_logout'),
                    'cssClass': 'shop-logout'
                });
                if (app.settings && !app.settings.isPunchout) {
                    items.push({
                        name: getDictionary('webshop_myPage_myPage'),
                        href: app.settings.paths.myPage
                    });
                }

                $.dcui.menu(items, $this);
            },
            toggleAgreementMessage: function (event) {
                if (event) {
                    event.preventDefault();
                }

                $.dcui.modal({
                    headerContent: getDictionary('webshop_layout_header_agreementModalHeader'),
                    content: '<div class="textWrapper">' + getDictionary('webshop_layout_header_agreementModalContent') + '</div>'
                });
            }
        },

        // quickshop: {
        //     addToOrder: function ($quickshop) {
        //         var $productId = $quickshop.find('#shop-quickshop-productId'),
        //             $amount = $quickshop.find('#shop-quickshop-amount'),
        //             $unit = $quickshop.find('#shop-quickshop-unit'),
        //             $unitText = $quickshop.find('.shop-quickshop-unit-display'),
        //             $quickshopProduct = $quickshop.find('#shop-quickshop-product'),
        //             $submit = $quickshop.find('input[type="submit"]'),
        //             $historyList = $('#shop-quickshop-log').find('tbody'),
        //             collectedProducts = [],
        //             productName = app.shop.quickshop.currentActiveLookup ? app.shop.quickshop.currentActiveLookup.productName : 'Udefineret',
        //             callback = function (response) {
        //                 var $view = app.views.getView('shopQuickshopHistoryResult');

        //                 $view.html(function (i, current) {
        //                     return current
        //                         .replace('{{Varenr}}', $productId.val())
        //                         .replace('{{Beskrivelse}}', productName)
        //                         .replace('{{Enhed}}', $unit.val())
        //                         .replace('{{Antal}}', $amount.val());
        //                 });

        //                 $productId.val('').focus();
        //                 $amount.val('').attr('disabled', 'disabled');
        //                 $unit.empty().attr('disabled', 'disabled').removeClass('hidden');
        //                 $unitText.addClass('hidden').html('');
        //                 $submit.attr('disabled', 'disabled');


        //                 // Mangler produktnavn i response data
        //                 $.dcui.toast(getDictionary('webshop_search_quickshop_toast_addedToOrder', productName), 'success', getDictionary('webshop_search_quickshop_toast_addedToOrder_header'));

        //                 app.shop.cart.updateMiniCart({
        //                     count: response.d.PreviewOrder.itemCount,
        //                     organicCount: response.d.PreviewOrder.ecoPct,
        //                     agreementCount: response.d.PreviewOrder.agreementPct,
        //                     noOfTooLate: response.d.PreviewOrder.noOfTooLate,
        //                     noOfExpired: response.d.PreviewOrder.noOfExpired
        //                 });

        //                 $productId.removeClass('accepted');

        //                 $historyList.prepend($view);
        //                 $quickshopProduct.hide();
        //                 app.shop.quickshop.currentActiveLookup = null;

        //             },
        //             errorCallback = function () {
        //                 app.log.error();
        //                 $.dcui.toast(getDictionary('webshop_search_quickshop_toast_addedToOrder_error'), 'error', getDictionary('webshop_search_quickshop_toast_addedToOrder_header'));
        //                 app.shop.quickshop.currentActiveLookup = null;
        //             };

        //         if ($productId.val() != '' && $amount.val() != '' && $amount.val() >= 1) {
        //             collectedProducts.push({
        //                 productId: $productId.val(),
        //                 unit: $unit.val(),
        //                 amount: $amount.val()
        //             });

        //             app.shop.orders.addProducts(collectedProducts, callback, errorCallback);
        //         }
        //         else {
        //             $.dcui.toast(getDictionary('webshop_search_quickshop_toast_addedToOrder_error_missingAmount'), 'error', getDictionary('webshop_search_quickshop_toast_addedToOrder_header'));
        //             $amount.focus().select();
        //         }

        //         app.log.event('Webshop', 'Quickshop', 'Add product');
        //     },

        //     currentActiveLookup: null,

        //     setActiveLookup: function (event, $elm) {
        //         var $quickshop = $('#shop-quickshop'),
        //             $amount = $quickshop.find('#shop-quickshop-amount'),
        //             $unit = $quickshop.find('#shop-quickshop-unit');

        //         $unit.attr('disabled', 'disabled');

        //         if (event.which == '13') {
        //             if ($elm.val().length > 0 && !$elm.hasClass('blocked')) {
        //                 $amount.removeAttr('disabled').focus();
        //             }
        //             event.preventDefault();
        //         }
        //         else {
        //             $elm.removeClass('blocked')
        //         }
        //     },

        //     lookupProductId: function ($elm) {
        //         var $quickshop = $elm.closest('#shop-quickshop'),
        //             $quickshopProduct = $quickshop.find('#shop-quickshop-product'),
        //             $amount = $quickshop.find('#shop-quickshop-amount'),
        //             $unit = $quickshop.find('#shop-quickshop-unit'),
        //             $productId = $quickshop.find('#shop-quickshop-productId'),
        //             $unitText = $quickshop.find('.shop-quickshop-unit-display'),
        //             $submit = $quickshop.find('input[type="submit"]'),
        //             $message = $quickshop.find('#quickMessage'); // Mangler

        //         // Clear values and disable fields
        //         $amount.val('').attr('disabled', 'disabled');
        //         $unit.empty().attr('disabled', 'disabled').removeClass('hidden');
        //         $unitText.addClass('hidden').html('');
        //         $elm.removeClass('accepted');

        //         // Validate input
        //         if ($elm.val().length > 0) {
        //             $elm.addClass('loading');

        //             //$unitText.css('display', 'none');
        //             var ajaxData = $elm.val();

        //             app.services.products.lookupProduct(ajaxData).then(function (response) {
        //                 if (response.result) {
        //                     if (response.result.units != null && response.result.units.length > 0) {
        //                         $elm.addClass('accepted');
        //                         $productId.removeClass('blocked');
        //                         $amount.val('').removeAttr('disabled').focus();
        //                         $unit.removeAttr('disabled');
        //                         $submit.removeAttr('disabled').removeClass('hidden');

        //                         for (var i = 0; i < response.result.units.length; i++) {
        //                             //$unit.append('<option value="' + data.units[i] + '">' + data.units[i] + '</option>');
        //                             $unit.append('<option' + (response.result.units[i].selected ? ' selected="selected"' : '') + ' value="' + response.result.units[i].name + '">' + response.result.units[i].name + ' (' + response.result.units[i].description + ')</option>');
        //                         }

        //                         if (response.result.units.length == 1) {
        //                             // Hide unit - show span text
        //                             $unit.addClass('hidden');
        //                             $unitText.removeClass('hidden').html(response.result.units[0].name + ' (' + response.result.units[0].description + ')');
        //                         }

        //                         $quickshopProduct.html('<span class="icon-checkmark"></span><span class="small">' + getDictionary('webshop_search_quickshop_lookupProduct_found', '<i>' + response.result.name + '</i>') + '</span>').show();

        //                         app.shop.quickshop.currentActiveLookup = {
        //                             productName: response.result.name
        //                         };

        //                         if (response.result.blockedByProductInfo) {
        //                             $('#blocked-by-foodinfo').removeClass('hidden');
        //                             $submit.prop('disabled', true).addClass('hidden');
        //                         }
        //                         else {
        //                             $('#blocked-by-foodinfo').addClass('hidden');
        //                         }
        //                     }
        //                 }
        //                 else if (response.result === null) {
        //                     $productId.removeClass('blocked');
        //                     $quickshopProduct.html('<span class="icon-denied"></span><span class="small">' + getDictionary('webshop_search_quickshop_lookupProduct_notFound', $elm.val()) + '</span>').show();
        //                     $elm.focus().select();
        //                 }
        //                 else {
        //                     $.dcui.toast(getDictionary('webshop_defaults_toast_errorText'), 'error');
        //                 }
        //                 setTimeout(function () { $elm.removeClass('loading'); }, 500);
        //             }, function () {
        //                 $quickshopProduct.html(getDictionary('webshop_search_quickshop_lookupProduct_notFound', $elm.val())).fadeIn();
        //                 $elm.focus().select();
        //                 setTimeout(function () { $elm.removeClass('loading'); }, 500);
        //             });
        //         }

        //         app.log.event('Webshop', 'Quickshop', 'Lookup product');
        //     },

        //     submitForm: function (event, $elm) {
        //         if (event.which == '13') {
        //             $elm.closest('form').submit();
        //         }
        //     },

        //     validateAmount: function ($elm) {
        //         var isNumber = !isNaN($elm.val() * 1) ? true : false;

        //         if ($elm.val() <= 0 || !isNumber) {
        //             $elm.val('0').focus().select();
        //         }
        //     },

        //     init: function () {
        //         setTimeout(function () {
        //             $('#shop-quickshop-productId').focus();
        //         }, 100);
        //     }
        // },

        // collectProducts: function (event) {
        //     if (event) {
        //         event.preventDefault();
        //     }

        //     var activeProductViewType = $('#shop-productList').find('tbody tr').length > 0 ? 'list' : 'gallery';

        //     var $products = activeProductViewType == 'list' ? $('#shop-productList') : $('#shop-productList-gallery'),
        //         isFromFavoriteList = $('#shop-productList-wrapper').hasClass('shop-productSearch-hasActiveFavoriteList'),
        //         $changedProducts = $products.find('.changed'),
        //         $form = $('#shop-productSearch'),
        //         $searchField = $form.find('.shop-productSearch-searchText'),
        //         collectedProductElements = [],
        //         collectedProducts = [],
        //         callback = function (response) {
        //             $.dcui.loader.stop();
        //             if (response.d && response.d.addedOrderlines > 0) {
        //                 //var productText = response.d.addedOrderlines == '1' ? 'nyt produkt' : 'nye produkter';
        //                 $.dcui.toast(getDictionary('webshop_cart_add_toast_basketAdded', response.d.addedOrderlines), 'success');
        //                 app.shop.cart.updateMiniCart({
        //                     count: response.d.PreviewOrder.itemCount,
        //                     organicCount: response.d.PreviewOrder.ecoPct,
        //                     agreementCount: response.d.PreviewOrder.agreementPct,
        //                     noOfTooLate: response.d.PreviewOrder.noOfTooLate,
        //                     noOfExpired: response.d.PreviewOrder.noOfExpired
        //                 });
        //                 $searchField.focus().select();
        //                 window.scrollTo(0, 0);

        //                 $changedProducts.removeClass('disabled');
        //                 for (var i = 0; i < collectedProductElements.length; i++) {
        //                     $(collectedProductElements[i]).addClass('shop-product-inBasket');
        //                 }
        //             }
        //             else {
        //                 var errorMessage = getDictionary('webshop_cart_add_toast_errorText');
        //                 if (response.d.statusCode === 416) {
        //                     var stockWarning = JSON.parse(response.d.error),
        //                         $product = $products.find('.shop-table-product[data-expiry="' + stockWarning.expiryRef + '"]');

        //                     $product.addClass('shop-product-loading-error');
        //                     setTimeout(function () {
        //                         $product.removeClass('shop-product-loading-error');
        //                     }, 2000);

        //                     $product.find('.expiry-stock').text(stockWarning.stock);

        //                     var expiryDate = new Date(stockWarning.expiryDate);
        //                     var deliveryDate = new Date(stockWarning.deliveryDate);
        //                     if (stockWarning.expiryDate && expiryDate >= deliveryDate) {
        //                         var inBasket = $product.hasClass('shop-product-inBasket');
        //                         if (stockWarning.stock === 0) {
        //                             errorMessage = getDictionary('webshop_cart_add_toast_expiry_stockZero', stockWarning.productId);
        //                         } else if (inBasket) {
        //                             errorMessage = getDictionary('webshop_cart_add_toast_expiry_amountLimitReached_inBasket', stockWarning.productId);
        //                         } else if (stockWarning.stock < stockWarning.amount) {
        //                             errorMessage = getDictionary('webshop_cart_add_toast_expiry_amountLimitReached', stockWarning.productId, stockWarning.stock);
        //                         }
        //                     }
        //                 }
        //                 $.dcui.toast(errorMessage, 'error', getDictionary('webshop_cart_add_toast_errorHeader'));
        //             }
        //         },
        //         errorCallback = function (response) {
        //             $.dcui.toast(getDictionary('webshop_cart_add_toast_errorText'), 'error');
        //         };

        //     $.dcui.loader();

        //     $changedProducts.each(function (i, product) {
        //         var $product = $(product),
        //             $quantity = $product.find('input.quantity');

        //         if ($quantity != null && $quantity.length > 0 && $quantity.val().length > 0 && $quantity.val() != 0) {
        //             var json = {
        //                 productId: $product.attr('data-id'),
        //                 amount: $quantity.val(),
        //                 unit: $product.attr('data-unit'),
        //                 expiryRef: $product.attr('data-expiry')
        //             }
        //             if (isFromFavoriteList) {
        //                 json.fromFavoriteList = true;
        //             }
        //             collectedProducts.push(json);
        //             collectedProductElements.push($product);
        //         }
        //         $product.removeClass('changed');
        //         if ($quantity != null && $quantity.length > 0 && $quantity.val() != 0) $quantity.val('');
        //     });

        //     $changedProducts.addClass('disabled');

        //     if (collectedProducts.length > 0) {
        //         app.shop.orders.addProducts(collectedProducts, callback, errorCallback);
        //         //$.dcui.loader.stop();
        //     }
        //     else {
        //         $.dcui.toast(getDictionary('webshop_cart_add_toast_errorText'), 'error');
        //         $.dcui.loader.stop();
        //     }
        // },

        obsoleteProducts: {
            removeProduct: function (event) {
                if (event) {
                    event.preventDefault();
                }

                var $this = $(this),
                    $product = $this.closest('tr'),
                    id = $product.attr('data-id'),
                    oldId = $product.attr('data-obsolete-id'),
                    productName = $product.attr('data-name'),
                    listName = $product.attr('data-list-name'),
                    $products = $("[data-obsolete-id=" + oldId + "]");

                var json = {
                    oldId: oldId,
                    replacementLogId: id,
                    productName: productName,
                    listName: listName,
                    countOfObsolete: $products.length
                };

                $.dcui.modal({
                    headerContent: getDictionary('webshop_modals_myPage_replacement_deleteItem_title'),
                    delay: true
                });
    
                $.ajax({
                    url: '/ModalMyPageDeleteObsoleteItem',
                    data: json,
                    type: 'GET'
                }).then(function (response) {
                    $.dcui.modal.delayContent({
                        content: response
                    })
                }).fail(function () {
                    $.dcui.modal.close();
                    $.dcui.toast(getDictionary('webshop_defaults_toast_errorText'), 'error');
                }); 
            },

            removeProductSubmit: function (event) {
                event.preventDefault();

                var $form = $(this),
                url = '/api/FavoriteLists/ReplaceObsolete',
                json = JSON.parse($form.attr('data-object')),
                $isSelectedAllLists = $form.find('input[name="selectAllLists"]');

                if ($isSelectedAllLists.length && $isSelectedAllLists[0].checked) {
                    json.replacementLogId = '';
                }

                app.services.favorites.removeProduct(json).then(function () {
                    $.dcui.toast(getDictionary('webshop_search_search_item_obsolete_deleted', json.productName), 'success');
                    window.setTimeout(function() { location.reload() }, 100);
                }, function(err) {
                    $.dcui.toast(getDictionary('webshop_search_search_item_obsolete_deleted_error', json.productName), 'error');
                    app.log.error("ajax.fail", { url: url, response: response });
                });   

                app.log.event('Webshop', 'Obsolete products', 'Replace product');
                $.dcui.modal.close();
            },

            replaceProduct: function (event) {
                event.preventDefault();

                var $this = $(this),
                    $product = $this.closest('tr'),
                    id = $product.attr('data-id'),
                    newId = $product.attr('data-new-id'),
                    oldId = $product.attr('data-obsolete-id'),
                    productName = $product.attr('data-name'),
                    newProductName = $product.attr('data-new-name'),
                    listName = $product.attr('data-list-name'),
                    $products = $("[data-obsolete-id=" + oldId + "]");

                var json = {
                    oldId: oldId,
                    newId: newId,
                    replacementLogId: id,
                    productName: productName,
                    newProductName: newProductName,
                    listName: listName,
                    countOfObsolete: $products.length
                };

                $.dcui.modal({
                    headerContent: getDictionary('webshop_modals_myPage_replacement_replaceItem_title'),
                    delay: true
                });
    
                $.ajax({
                    url: '/ModalMyPageReplaceObsoleteItem',
                    data: json,
                    type: 'GET'
                }).then(function (response) {
                    $.dcui.modal.delayContent({
                        content: response
                    })
                }).fail(function () {
                    $.dcui.modal.close();
                    $.dcui.toast(getDictionary('webshop_defaults_toast_errorText'), 'error');
                });  
            },

            replaceProductSubmit: function (event) {
                event.preventDefault();

                var $form = $(this),
                url = '/api/FavoriteLists/ReplaceObsolete',
                json = JSON.parse($form.attr('data-object')),
                $isSelectedAllLists = $form.find('input[name="selectAllLists"]');

                if ($isSelectedAllLists.length && $isSelectedAllLists[0].checked) {
                    json.replacementLogId = '';
                }
                app.services.favorites.handleObsoleteProduct(json).then(function () {
                    $.dcui.toast(getDictionary('webshop_search_search_item_obsolete_replaced', json.productName, json.newProductName), 'success');
                    window.setTimeout(function() { location.reload() }, 100);
                }, function (err) {
                    $.dcui.toast(getDictionary('webshop_search_search_item_obsolete_replaced_error', json.productName), 'error');
                    app.log.error("ajax.fail", { url: url, response: response });
                });  

                app.log.event('Webshop', 'Obsolete products', 'Replace product');
                $.dcui.modal.close();
            }
        },

        //orders: {
            // acceptOrder: function (event) {
            //     event.preventDefault();

            //     var $this = $(this),
            //         $form = $this.closest('form'),
            //         $submit = $form.find('.shop-orderAcceptSubmit'),
            //         $comments = $form.find('.comments'),
            //         json = {
            //             comments: $comments.val(),
            //             orderType: "Webshop"
            //         },
            //         JSONpostData = null,
            //         isPunchOut = $submit.hasClass('isPunchOut');

            //     if ($this.attr('data-has-blocked-product') == 'True') {
            //         $.dcui.toast(getDictionary('webshop_order_complete_acceptOrder_toast_errorText_blocked_item_in_cart_error'), 'error');
            //         return;
            //     }

            //     if ($this.attr('data-has-obsolete-product') == 'True') {
            //         $.dcui.toast(getDictionary('webshop_order_complete_acceptOrder_toast_errorText_obsolete_item_in_cart_error'), 'error');
            //         return;
            //     };

            //     //$this.attr('disabled', 'disabled').addClass('ui-button-disabled');
            //     //jcd.helpers.loader.begin('Vent venligst, mens vi behandler din ordre.');

            //     if ($form.find('.shop-orderpart-product').length == 0) {
            //         $.dcui.modal({
            //             headerContent: getDictionary('webshop_order_complete_acceptOrder_toast_NoProductsHeader'),
            //             content: '<p>' + getDictionary('webshop_order_complete_acceptOrder_toast_NoProductsText') + '</p>'
            //         });
            //         $submit.removeAttr('disabled').removeClass('disabled ui-button-disabled');
            //         return false;
            //     }
            //     else {
            //         $.dcui.loader({ content: getDictionary('webshop_order_complete_acceptOrder_toast_loader') });
            //     }

            //     app.services.orders.complete(json, { isPunchOut: isPunchOut }).then(
            //         function (response) {
            //             setTimeout(function () {
            //                 window.location = isPunchOut ? "/webbshop/visa-eller-avsluta-order/tak-for-din-bestilling/PunchoutOrderComplete" : "/webbshop/visa-eller-avsluta-order/tack-foer-din-bestaellning";
            //             }, 500);
            //         },
            //         function (response) {
            //             $.dcui.loader.stop();
            //             var errorContent = getDictionary('webshop_order_complete_acceptOrder_toast_errorText');
            //             if (response.statusCode === 416) {
            //                 var stockWarning = JSON.parse(response.message);
            //                 var expiryDate = new Date(stockWarning.expiryDate);
            //                 var deliveryDate = new Date(stockWarning.deliveryDate);
            //                 if (!stockWarning.expiryDate || expiryDate < deliveryDate) {
            //                     errorContent = getDictionary('webshop_order_complete_acceptOrder_toast_error_expiry_datepassed', stockWarning.productId);
            //                 } else if (stockWarning.stock === 0) {
            //                     errorContent = getDictionary('webshop_order_complete_acceptOrder_toast_error_expiry_stockZero', stockWarning.productId);
            //                 } else if (stockWarning.stock < stockWarning.amount) {
            //                     errorContent = getDictionary('webshop_order_complete_acceptOrder_toast_error_expiry_amountLimitReached', stockWarning.stock, stockWarning.productId);
            //                 }
            //             }
            //             $.dcui.modal({
            //                 headerContent: getDictionary('webshop_order_complete_acceptOrder_toast_errorHeader'),
            //                 content: '<p>' + errorContent + '</p>'
            //             });

            //             $submit.removeAttr('disabled').removeClass('disabled ui-button-disabled');
            //         }
            //     );
            // },

            // addProducts: function (products, callback, errorCallback) {
            //     var onSuccessCallback = callback ? callback : function () { },
            //         onErrorCallback = errorCallback ? errorCallback : function () { };

            //     app.services.cart.addToOrder(products).then(function (response) {
            //         onSuccessCallback(response);
            //     }, function () {
            //         onErrorCallback();
            //     })

            //     return;
            // },

            // changeProductUnit: function (event) {
            //     event.preventDefault();

            //     var $this = $(this),
            //         $product = $this.closest('tr.shop-orderpart-product'),
            //         $orderpart = $product.closest('.shop-orderpart'),
            //         deliveryDate = $orderpart.attr('data-deliverydate'),
            //         productId = $product.attr('data-id'),
            //         $quantity = $product.find('input.quantity'),
            //         quantity = $quantity.val(),
            //         $unit = $product.find('select.unit'),
            //         selectedUnit = $unit.val(),
            //         currentUnit = $product.attr('data-selected-unit'),
            //         lineNo = $product.attr('data-lineno'),
            //         isWeightItem = $product.attr('data-weightitem') == 'True',
            //         productDescription = $product.find('.productTitle').text();

            //     if (quantity.match(/[^0-9]/g) != null && selectedUnit != 'KG') {
            //         $product.addClass('shop-product-loading-error');
            //         //$this.parent().append('<div class="ui-tooltip ui-tooltip-error"><div class="ui-tooltip-content">Decimaltal kan kun accepteres på enheden KG <a class="ui-tooltip-close"><span class="icon-close"></span></a></div></div>');
            //         $.dcui.tooltip({
            //             $wrapper: $this.parent(),
            //             text: getDictionary('webshop_order_changeProductUnit_kgWarning'),
            //             status: 'error'
            //         });

            //         $unit.val(currentUnit);

            //         setTimeout(function () {
            //             $product.removeClass('shop-product-loading-error');
            //         }, 2000);
            //         return;
            //     }

            //     if (deliveryDate && productId && quantity && selectedUnit && lineNo) {
            //         $quantity.attr('disabled', 'disabled');
            //         $unit.attr('disabled', 'disabled');
            //         $product.addClass('shop-product-loading');

            //         var data = {
            //             deliveryDate: deliveryDate.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"),
            //             productId: productId,
            //             quantity: quantity,
            //             selectedUnit: selectedUnit,
            //             lineNo: lineNo
            //         }

            //         app.services.orders.updateOrderline(data).then(function (response) {
            //             try {
            //                 $product.attr({
            //                     'data-selected-unit': selectedUnit,
            //                     'data-broachfactor': $unit.find('option:selected').attr('data-broachfactor')
            //                 });

            //                 var linePrice = isWeightItem && response.unit != 'KG' ? '-' : response.linePrice;
            //                 //var linePrice = response.linePrice;
            //                 var totalPrice = response.totalPrice;
            //                 $product.find('.unitPrice .price').html(response.unitPrice);
            //                 $product.find('.linePrice').html(linePrice);
            //                 $orderpart.find('.totalPrice').html(totalPrice);
            //                 $.dcui.toast(getDictionary('webshop_order_changeProductUnit_lineUpdated', '<i>' + productDescription + '</i>'), 'success');

            //                 app.shop.cart.updateMiniCart({
            //                     count: response.previewOrder.itemCount,
            //                     organicCount: response.previewOrder.ecoPct,
            //                     agreementCount: response.previewOrder.agreementPct,
            //                     noOfTooLate: response.previewOrder.noOfTooLate,
            //                     noOfExpired: response.previewOrder.noOfExpired
            //                 });

            //                 $product.addClass('shop-product-loading-success');
            //                 if (response.suppressWeight == true) {
            //                     $product.addClass('suppresWeight');
            //                 }
            //                 else {
            //                     $product.removeClass('suppresWeight');
            //                 }

            //                 $quantity.removeAttr('disabled');
            //                 $unit.removeAttr('disabled');
            //                 $product.removeClass('shop-product-loading');

            //                 setTimeout(function () {
            //                     $product.removeClass('shop-product-loading-success');
            //                 }, 2000);
            //             }
            //             catch (ex) {
            //                 app.log.error('itemCount-error: changeProductUnit', { response: response, data: data });
            //             }
            //         }, function () {
            //             $.dcui.toast(getDictionary('webshop_order_changeProductUnit_toast_error', '<i>' + productDescription + '</i>'), 'error');
            //             $product.addClass('shop-product-loading-error');
            //             $quantity.removeAttr('disabled');
            //             $unit.removeAttr('disabled').val(currentUnit);
            //             $product.removeClass('shop-product-loading');

            //             setTimeout(function () {
            //                 $product.removeClass('shop-product-loading-error');
            //             }, 2000);
            //         });
            //     }
            //     else {
            //         // Errorlog
            //     }
            // },

            // changeProductQuantity: function (obj) {
            //     var $this = null;

            //     if (obj && obj.type) {
            //         obj.preventDefault();
            //         $this = $(this);
            //     }
            //     else {
            //         $this = $(obj);
            //     }

            //     var $product = $this.closest('tr.shop-orderpart-product'),
            //         $orderpart = $product.closest('.shop-orderpart'),
            //         deliveryDate = $orderpart.attr('data-deliverydate'),
            //         productId = $product.attr('data-id'),
            //         $quantity = $product.find('input.quantity'),
            //         quantity = $quantity.val(),
            //         fallbackQuantity = $product.attr('data-amount'),
            //         $unit = $product.find('select.unit'),
            //         selectedUnit = $product.attr('data-selected-unit'),
            //         lineNo = $product.attr('data-lineno'),
            //         isWeightItem = $product.attr('data-weightitem') == 'True',
            //         productDescription = $product.find('.productTitle').text(),
            //         $warnings = $product.find('.warnings');

            //     // Disable fields
            //     $quantity.attr('disabled', 'disabled');
            //     $unit.attr('disabled', 'disabled');
            //     $product.addClass('shop-product-loading');

            //     if (quantity && quantity < 1) {
            //         app.shop.orders.removeProduct($product);
            //     } else {
            //         if (deliveryDate && productId && quantity && selectedUnit && lineNo) {
            //             var data = {
            //                 deliveryDate: deliveryDate.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"),
            //                 productId: productId,
            //                 quantity: quantity,
            //                 selectedUnit: selectedUnit,
            //                 lineNo: lineNo
            //             }

            //             app.services.orders.updateOrderline(data).then(function (response) {
            //                 try {
            //                     var linePrice = isWeightItem && response.unit != 'KG' ? '-' : response.linePrice;
            //                     var totalPrice = response.totalPrice;
            //                     $product.attr('data-quantity', quantity);
            //                     $product.attr('data-amount', quantity);
            //                     $product.find('.unitPrice .price').html(response.unitPrice);
            //                     $product.find('.linePrice').html(linePrice);
            //                     $orderpart.find('.totalPrice').html(totalPrice);
            //                     $.dcui.toast(getDictionary('webshop_order_changeProductQuantity_lineUpdated', '<i>' + productDescription + '</i>'), 'success');
            //                     $product.addClass('shop-product-loading-success');

            //                     $warnings.find(".warning").remove();
            //                     if (response.warnings && response.warnings.length > 0) {
            //                         for (var i = 0; i < response.warnings.length; i++) {
            //                             var warning = response.warnings[i];
            //                             if (warning.typeName != "outOfStock") {
            //                                 var warningText = warning.message;
            //                                 if (warning.typeName == "highPrice") {
            //                                     warningText = getDictionary('webshop_order_warnings_highPrice');
            //                                 }
            //                                 $warnings.append('<p class="warning ' + warning.typeName + '"><i class="icon-report-problem"></i><span>' + warningText + '</span></p>');
            //                             }
            //                         }
            //                     }
            //                     if ($warnings.children().length > 0) {
            //                         $product.addClass('hasWarnings');
            //                     } else {
            //                         $product.removeClass('hasWarnings');
            //                     }

            //                     app.shop.cart.updateMiniCart({
            //                         count: response.previewOrder.itemCount,
            //                         organicCount: response.previewOrder.ecoPct,
            //                         agreementCount: response.previewOrder.agreementPct,
            //                         noOfTooLate: response.previewOrder.noOfTooLate,
            //                         noOfExpired: response.previewOrder.noOfExpired
            //                     });

            //                     setTimeout(function () {
            //                         $product.removeClass('shop-product-loading-success');
            //                     }, 2000);

            //                     $quantity.removeAttr('disabled');
            //                     $unit.removeAttr('disabled');
            //                     $product.removeClass('shop-product-loading');

            //                 }
            //                 catch (ex) {
            //                     $quantity.val(fallbackQuantity);
            //                     $product.attr('data-quantity', quantity);
            //                     $product.addClass('shop-product-loading-error');

            //                     setTimeout(function () {
            //                         $product.removeClass('shop-product-loading-error');
            //                     }, 2000);

            //                     $.dcui.toast(getDictionary('webshop_defaults_toast_errorText'), 'error');

            //                     app.log.error('itemCount-error: changeProductQuantity', {
            //                         response: response,
            //                         data: { data: data, ex: ex }
            //                     });
            //                 }
            //             })
            //         }
            //         else {
            //             $quantity.val(fallbackQuantity);
            //             $product.attr('data-quantity', quantity);
            //             $product.addClass('shop-product-loading-error');

            //             setTimeout(function () {
            //                 $product.removeClass('shop-product-loading-error');
            //             }, 2000);

            //             var errorMessage = getDictionary('webshop_order_changeProductQuantity_error', productDescription);
            //             var useTooltip = false;
            //             if (response.statusCode === 416) {
            //                 var stockWarning = JSON.parse(response.errorMessage);
            //                 var expiryDate = new Date(stockWarning.expiryDate);
            //                 var deliveryDate = new Date(stockWarning.deliveryDate);
            //                 var warningText = null;
            //                 if (!stockWarning.expiryDate || expiryDate < deliveryDate) {
            //                     errorMessage = getDictionary('webshop_order_changeProductQuantity_expiry_datepassed', stockWarning.productId);
            //                     warningText = getDictionary('webshop_order_warnings_outOfStock_datepassed');
            //                 } else if (stockWarning.stock === 0) {
            //                     errorMessage = getDictionary('webshop_order_changeProductQuantity_expiry_stockZero', stockWarning.productId);
            //                     warningText = getDictionary('webshop_order_warnings_outOfStock_stockZero');
            //                 } else if (stockWarning.stock < stockWarning.amount) {
            //                     useTooltip = true;
            //                     errorMessage = getDictionary('webshop_order_changeProductQuantity_expiry_amountLimitReached', stockWarning.stock);
            //                     if (Number($quantity.val() > stockWarning.stock)) {
            //                         warningText = getDictionary('webshop_order_warnings_outOfStock_amountLimitReached', stockWarning.stock);
            //                     }
            //                 }
            //                 if (warningText) {
            //                     var $warning = $warnings.find(".warning.outOfStock");
            //                     if ($warning.length === 0) {
            //                         $warnings.append('<p class="warning outOfStock"><i class="icon-report-problem"></i><span>' + warningText + '</span></p>');
            //                     } else {
            //                         $warning.find("span").text(warningText);
            //                     }
            //                     $product.addClass('hasWarnings');
            //                 }
            //             }
            //             if (useTooltip) {
            //                 $.dcui.tooltip({
            //                     $wrapper: $this.parent(),
            //                     text: errorMessage,
            //                     status: 'error'
            //                 });
            //             }
            //             else {
            //                 $.dcui.toast(errorMessage, 'error');
            //             }
            //         }
            //     }
            // },

            // reachBroachLimit: function (event) {
            //     event.preventDefault();

            //     var $this = $(this),
            //         $product = $this.closest('.shop-orderpart-product'),
            //         amount = 1;

            //     if ($product.attr('data-broachfactor') != null) {
            //         amount = $product.attr('data-broachfactor');
            //     }
            //     else if ($product.attr('data-broachlimit') != null) {
            //         amount = $product.attr('data-broachlimit');
            //     }

            //     $product.find('input.quantity').val(amount);

            //     app.shop.orders.changeProductQuantity(this);
            // },

            // removeProduct: function ($product) {
            //     var $orderpart = $product.closest('.shop-orderpart'),
            //         deliveryDate = $orderpart.attr('data-deliverydate'),
            //         productId = $product.attr('data-id'),
            //         $quantity = $product.find('input.quantity'),
            //         $unit = $product.find('select.unit'),
            //         amount = 0,
            //         selecedUnit = $product.attr('data-selected-unit'),
            //         lineNo = $product.attr('data-lineno'),
            //         orderpartProductCount = $orderpart.find('tr.shop-orderpart-product').length,
            //         $orderpartWrapper = $orderpart.closest('.shop-orderpart-wrapper'),
            //         productDescription = $product.find('.productTitle').text();
            //     $hasAlternative = $product.attr('data-hasAlternative');

            //     console.log('orderpart', orderpartProductCount);

            //     // Disable fields
            //     $quantity.attr('disabled', 'disabled');
            //     $unit.attr('disabled', 'disabled');
            //     if (orderpartProductCount === 1) {
            //         $orderpartWrapper.addClass('shop-orderpart-wrapper-loading');
            //     }
            //     else {
            //         if ($hasAlternative == 'True') {
            //             $product.next().addClass('shop-product-loading');
            //         }
            //         $product.addClass('shop-product-loading');
            //     }


            //     if (deliveryDate && productId && lineNo) {
            //         var data = {
            //             deliveryDate: deliveryDate,
            //             productId: productId,
            //             quantity: 0,
            //             selectedUnit: null,
            //             lineNo: lineNo
            //         };

            //         var disableLoading = function () {
            //             $quantity.removeAttr('disabled');
            //             $unit.removeAttr('disabled');

            //             if ($hasAlternative == 'True') {
            //                 $product.next().removeClass('shop-product-loading');
            //             }
            //             $product.removeClass('shop-product-loading');
            //             $orderpartWrapper.removeClass('shop-orderpart-wrapper-loading');
            //         };

            //         app.services.orders.updateOrderline(data).then(function (response) {
            //                 var totalPrice = response.totalPrice;
            //                 $.dcui.toast(getDictionary('webshop_order_removeProduct_lineDeleted', '<i>' + productDescription + '</i>'), 'success');

            //                 if (response.previewOrder) {
            //                     app.shop.cart.updateMiniCart({
            //                         count: response.previewOrder.itemCount,
            //                         organicCount: response.previewOrder.ecoPct,
            //                         agreementCount: response.previewOrder.agreementPct,
            //                         noOfTooLate: response.previewOrder.noOfTooLate,
            //                         noOfExpired: response.previewOrder.noOfExpired
            //                     });
            //                 }

            //                 if (orderpartProductCount == 1) {
            //                     $orderpartWrapper.addClass('shop-orderpart-wrapper-delete-success');
            //                 }
            //                 else {
            //                     if ($hasAlternative == 'True') {
            //                         $product.next().addClass('shop-product-delete-success');
            //                     }
            //                     $product.addClass('shop-product-delete-success');
            //                     $orderpart.find('.totalPrice').html(totalPrice);
            //                 }

            //                 setTimeout(function () {
            //                     if (orderpartProductCount == 1) {
            //                         $orderpartWrapper.remove();
            //                     }
            //                     else {
            //                         if ($hasAlternative == 'True') {
            //                             $product.next().remove();
            //                         }
            //                         $product.remove();

            //                         if ($hasAlternative == 'True') {
            //                             location.reload(true);
            //                         }
            //                     }
            //                     app.shop.orders.checkExpiryProductDisclaimer();
            //                 }, 400);
            //                 disableLoading();
            //         }, function () {
            //             $.dcui.toast(getDictionary('webshop_order_removeProduct_toast_error'), 'error');
            //             disableLoading();
            //         });
            //     }
            //     else {
            //         $.dcui.toast(getDictionary('webshop_order_removeProduct_toast_error'), 'error');
            //         // Errorhandler
            //     }
            // },

            // checkExpiryProductDisclaimer: function () {
            //     var $orderPage = $('#shopOrder'),
            //         $disclaimer = $orderPage.find('.expiry-products-disclaimer');
            //     if ($orderPage.has('.shop-orderpart-product .expiryComment').length > 0) {
            //         $disclaimer.removeClass("hide");
            //     } else {
            //         $disclaimer.addClass("hide");
            //     }
            // },

            // Deprecated
            //updateProduct: function (data, callback, errorCallback) {
            //    var url = '/base/order/UpdateOrderLine/' + data.deliveryDate + '/' + data.productId + '/' + data.quantity + '/' + data.selectedUnit + '/' + data.lineNo;
            //    $.ajax({
            //        url: url,
            //        type: 'POST',
            //        dataType: 'json'
            //    }).done(function (response) {
            //        if (callback) {
            //            callback(response);
            //        }
            //    }).fail(function (response) {
            //        app.log.error("ajax.fail", { url: url, response: response });
            //        if (errorCallback) {
            //            errorCallback();
            //        }
            //    });
            //},

            // handleRemoveProduct: function (event) {
            //     event.preventDefault();
            //     console.log('handleRemoveProduct');

            //     app.shop.orders.removeProduct($(this).closest('tr.shop-orderpart-product'));
            // },

            // handleAddAlternativeProductToCart: function (event) {
            //     event.preventDefault();
            //     console.log('handleAddAlternativeProductToCart');

            //     app.shop.cart.addAlternativeProductToCart($(this).closest('tr.shop-orderpart-product'));
            // },

            // handleValidateQuantity: function (event) {
            //     var $this = $(this),
            //         $product = $this.closest('tr.shop-table-product'),
            //         quantity = $this.val(),
            //         fallbackQuantity = $product.attr('data-quantity'),
            //         unit = $product.attr('data-selected-unit'),
            //         validate = app.products.product.validateQuantity({ quantity: quantity, unit: unit });

            //     console.log('validate', [unit, validate]);

            //     if (!validate.success) {
            //         $this.val(fallbackQuantity).addClass('error');
            //         $.dcui.tooltip({
            //             $wrapper: $this.parent(),
            //             text: (validate.message ? validate.message : getDictionary('webshop_form_validateError_default')),
            //             status: 'error'
            //         });
            //     }
            //     else {
            //         $product.attr('data-quantity', quantity)
            //             .addClass('changed')
            //             .removeClass('error')
            //             .find('.ui-tooltip').remove();

            //         if (event.which == 13) {
            //             event.preventDefault();
            //             $this.blur();
            //         }
            //     }

            //     return;

            //     var $this = $(this),
            //         $product = $this.closest('.shop-table-product'),
            //         quantity = $this.val(),
            //         unit = $product.attr('data-unit'),
            //         validate = app.products.product.validateQuantity({ quantity: quantity, unit: unit });

            //     if (!validate.success) {
            //         $this.val('').focus().addClass('error');
            //         $.dcui.tooltip({
            //             $wrapper: $this.parent(),
            //             text: (validate.message ? validate.message : "Den indtastede værdig var ugyldig"),
            //             status: 'error'
            //         });
            //     }
            //     else {
            //         $product.attr('data-current-quantity', quantity)
            //             .addClass('changed')
            //             .removeClass('error')
            //             .find('.ui-tooltip').remove();

            //         if (event.which == 13) {
            //             app.shop.collectProducts();
            //             $this.blur();
            //             event.preventDefault();
            //         }

            //         $this.removeClass('error');
            //     }


            // },

            // validateQuantity: function (event) {
            //     var $this = $(this),
            //         $product = $this.closest('tr.shop-table-product'),
            //         quantity = $this.val(),
            //         oldQuantity = $product.attr('data-quantity'),
            //         unit = $product.attr('data-selected-unit');



            //     if (quantity.match(/[^0-9]/g) && unit != 'KG') {
            //         console.log('match 1');
            //         var val = parseInt(quantity);
            //         if (!isNaN(val)) {
            //             $this.val(oldQuantity).focus();
            //         }
            //         else {
            //             $this.val(oldQuantity).focus();
            //         }
            //         //$this.parent().append('<div class="ui-tooltip ui-tooltip-error"><div class="ui-tooltip-content">Decimaltal kan kun accepteres på enheden KG<a class="ui-tooltip-close"><span class="icon-close"></span></a></div></div>');
            //         $.dcui.tooltip({
            //             $wrapper: $this.parent(),
            //             text: getDictionary('webshop_order_changeProductUnit_kgWarning'),
            //             status: 'error'
            //         });
            //     }
            //     else if (quantity.match(/^[0-9]+(,[0-9]*)?/) && quantity != '' && unit == 'KG') {
            //         console.log('match 2');
            //         $this.val(oldQuantity).focus().addClass('error');
            //         //$this.parent().append('<div class="ui-tooltip ui-tooltip-error"><div class="ui-tooltip-content">Skriv ikke tekst eller punktum i antal-felt<a class="ui-tooltip-close"><span class="icon-close"></span></a></div></div>');
            //         $.dcui.tooltip({
            //             $wrapper: $this.parent(),
            //             text: getDictionary('webshop_form_validateError_amountField_notext'),
            //             status: 'error'
            //         });
            //     }
            //     else if (!quantity.match(/^[0-9]+(,[0-9]{0,2})?$/) && quantity != '' && unit == 'KG') {
            //         console.log('match 3');
            //         $this.val(oldQuantity).focus().addClass('error');
            //         //$this.parent().append('<div class="ui-tooltip ui-tooltip-error"><div class="ui-tooltip-content">Skriv ikke 3 decimaler efter komma<a class="ui-tooltip-close"><span class="icon-close"></span></a></div></div>');
            //         $.dcui.tooltip({
            //             $wrapper: $this.parent(),
            //             text: getDictionary('webshop_form_validateError_tooManyDecimals'),
            //             status: 'error'
            //         });
            //     }
            //     else {
            //         console.log('match 4');
            //         $product.attr('data-current-quantity', quantity);
            //         $product.addClass('changed');
            //         $product.find('.ui-tooltip').remove();
            //         $product.removeClass('error');

            //         if (event.which == 13) {
            //             event.preventDefault();
            //         }
            //     }
            // }
        //},

        orderHistory: {
            reorder: function (event) {
                event.preventDefault();

                var $this = $(this),
                    orderId = $this.attr('data-orderid');
                // google analytics ecommerce add to basket
                var gaAddToBasket = function () {
                    var products = $(".shop-table-product");
                    var gaProducts = $.map(products, (product) => {
                        var id = $(product).find(".col1").text(),
                        name = $(product).find(".col2").text(),
                        brand = $(product).find(".col3").text(),
                        quantity = $(product).find(".col5").text(),
                        inactiveProduct = $(product).hasClass("inactive-product")
                        depositProduct = $(product).hasClass("deposit-product");
                        if (inactiveProduct || depositProduct || $(product).children().length < 2 || id == undefined || quantity == undefined) {
                            return null;
                        }
                        
                        var productJson = {
                            id: id,
                            category: '',
                            brand: brand,
                            name: name,
                            quantity: quantity,
                            variant: '',
                            dimension1: 'SvenskCater'

                        };
                        return productJson;

                    }).filter(x => x != null);
                    window["dataLayer"].push({ ecommerce: null }); 
                    window["dataLayer"].push({
                        event: 'addToCart',
                        ecommerce: {
                            currencyCode: 'SEK',
                            add: {
                                products: gaProducts
                            }
                        }
                    });
                }

                $.dcui.modal({
                    isConfirm: true,
                    headerContent: getDictionary('webshop_myPage_history_order_reOrder_confirm_header'),
                    content: '<p>' + getDictionary('webshop_myPage_history_order_reOrder_confirm_text', orderId) + '</p>',
                    confirm: {
                        onConfirm: function () {
                            var url = '/api/order/ReOrder?orderId=' + orderId;

                            $.dcui.modal.close();
                            $.dcui.loader({
                                content: getDictionary('webshop_myPage_history_order_reOrder_confirm_loader')
                            });
                            $.ajax({
                                url: url,
                                type: 'POST'
                            }).then(function (response) {
                                $.dcui.loader.stop();
                                $.dcui.toast(getDictionary('webshop_myPage_history_order_reOrder_addedToBasket'), 'success');
                                gaAddToBasket();
                                setTimeout(function () {
                                    window.location.reload();
                                }, 1000);

                                //if (response.d != null && response.d.addedOrderlines > 0) {
                                //    $.dcui.toast('Du har tilføjet <i>' + response.d[0] + '</i> emne(r) til din ordre! <a href="/webbshop/visa-eller-avsluta-order.aspx">Tryk her for at gå til bestilling</a>', 'success');
                                //    app.shop.cart.updateMiniCart(response.d[1]);
                                //}
                                //else {
                                //    $.dcui.toast('Der sket en fejl og der blev ikke tilføjet noget til kurven', 'error', 'Fejl');
                                //}

                            }).fail(function (response) {
                                app.log.error("ajax.fail", { url: url, response: response });
                            });
                        }
                    },
                    acceptText: getDictionary('webshop_myPage_history_order_reOrder_confirm_accept'),
                    acceptClass: 'ui-button-accept',
                    cancelText: getDictionary("webshop_modals_general_cancelRegret")
                });

                app.log.event('Webshop', 'History', 'Re-order');
            }
        },

        // productList: {
        //     validateQuantity: function (event) {
        //         var $this = $(this),
        //             $product = $this.closest('.shop-table-product'),
        //             quantity = $this.val(),
        //             unit = $product.attr('data-unit');

        //         if (quantity.match(/[^0-9]/g) && unit != 'KG') {
        //             console.log('match 1');
        //             var val = parseInt(quantity);
        //             if (!isNaN(val)) {
        //                 $this.val('').focus();
        //             }
        //             else {
        //                 $this.val('0').focus();
        //             }
        //             //$this.parent().append('<div class="ui-tooltip ui-tooltip-error"><div class="ui-tooltip-content">Decimaltal kan kun accepteres på enheden KG <a class="ui-tooltip-close"><span class="icon-close"></span></a></div></div>');
        //             $.dcui.tooltip({
        //                 $wrapper: $this.parent(),
        //                 text: getDictionary('webshop_order_changeProductUnit_kgWarning'),
        //                 status: 'error'
        //             });
        //         }
        //         else if (!quantity.match(/^[0-9]+(,[0-9]*)?/) && quantity != '' && unit == 'KG') {
        //             $(amount).val($(amount).attr('data-amount'));
        //             $this.val('').focus().addClass('error');
        //             //$this.parent().append('<div class="ui-tooltip ui-tooltip-error"><div class="ui-tooltip-content">Skriv ikke tekst eller punktum i antal-felt <a class="ui-tooltip-close"><span class="icon-close"></span></a></div></div>');
        //             $.dcui.tooltip({
        //                 $wrapper: $this.parent(),
        //                 text: getDictionary('webshop_form_validateError_amountField_notext'),
        //                 status: 'error'
        //             });
        //         }
        //         else if (!quantity.match(/^[0-9]+(,[0-9]{0,2})?/) && quantity != '' && unit == 'KG') {
        //             console.log('match 3');
        //             //$(amount).val($(amount).attr('data-amount'));
        //             $this.val('').focus().addClass('error');
        //             //$this.parent().append('<div class="ui-tooltip ui-tooltip-error"><div class="ui-tooltip-content">Skriv ikke 3 decimaler efter komma <a class="ui-tooltip-close"><span class="icon-close"></span></a></div></div>');
        //             $.dcui.tooltip({
        //                 $wrapper: $this.parent(),
        //                 text: getDictionary('webshop_form_validateError_tooManyDecimals'),
        //                 status: 'error'
        //             });
        //         }
        //         else {
        //             $product.attr('data-current-quantity', quantity);
        //             $product.addClass('changed');
        //             $product.find('.ui-tooltip').remove();
        //             $product.removeClass('error');

        //             if (event.which == 13) {
        //                 if ($product.closest('#shop-productList').length > 0) {
        //                     app.shop.collectProducts();
        //                 }
        //                 $this.blur();
        //                 event.preventDefault();
        //             }
        //         }

        //         //if (!app.helpers.isNumber($this.val()) && $this.val().length > 0) {
        //         //    $this.val($product.attr('data-current-amount'));
        //         //}
        //         //else if ($this.val().length > 0) {
        //         //    $product.attr('data-current-amount', $this.val());
        //         //}


        //     }
        // },

        // products: {


        // }
    },

    settings: webshopSettings,

    user: {
        // userSettingsEmails: {
        //     addEmail: function (event) {
        //         if (event) {
        //             event.preventDefault();
        //         }

        //         var $this = $(this),
        //             $wrapper = $this.closest('.ui-form-fieldwrapper'),
        //             $email = $wrapper.find('.shop-form-userSettings-addEmail-value'),
        //             $list = $wrapper.find('ul');

        //         $email.removeClass('ui-form-fielderror');
        //         $wrapper.find('.ui-form-fielderror-message').remove();

        //         if (app.helpers.isEmail($email.val())) {
        //             var html = '<li data-email="' + $email.val() + '"><small><span class="content">' + $email.val() + '</span></small><a href="#" class="ui-button ui-button-small ui-button-secondary shop-form-userSettings-removeEmail"><span class="icon-delete"></span>&nbsp;&nbsp;' + getDictionary('webshop_myPage_settings_button_remove') + '</a></li>';
        //             $list.append(html);
        //             $email.val('').focus();
        //         }
        //         else {
        //             $email.addClass('ui-form-fielderror');
        //             $email.after('<span class="ui-form-fielderror-message">' + getDictionary('webshop_form_validateError_email') + '</span>');
        //         }
        //     },

        //     removeEmail: function (event) {
        //         event.preventDefault();
        //         event.stopPropagation();

        //         console.log('remove');

        //         var $this = $(this),
        //             $item = $this.closest('li');

        //         $item.remove();
        //     },

        //     showUserSettingsEmail: function (event) {
        //         event.preventDefault();

        //         var $this = $(this),
        //             $wrapper = $this.closest('.ui-form-fieldwrapper'),
        //             $container = $wrapper.find('.shop-form-userSettings-addEmail-wrapper');

        //         $this.addClass('hidden');
        //         $container.removeClass('hidden');

        //         setTimeout(function () {
        //             $container.find('input[type="text"]').focus();
        //         }, 100);
        //     },

        //     hideUserSettingsEmail: function (event) { }
        // },

        // changePasswordHandleSubmit: function (event) {
        //     event.preventDefault();

        //     var $form = $(this),
        //         $newPassword = $form.find('input[name="newPassword"]'),
        //         $oldPassword = $form.find('input[name="oldPassword"]'),
        //         json = {
        //             newPassword: $newPassword.val(),
        //             oldPassword: $oldPassword.val()
        //         },
        //         url = '/jcd/jsondata.aspx/ChangePassword';

        //     $.ajax({
        //         url: url,
        //         type: 'POST',
        //         data: JSON.stringify(json),
        //         contentType: "application/json; charset=utf-8",
        //         dataType: 'json'
        //     }).success(function (response) {
        //         $.dcui.modal.close();
        //         $.dcui.toast('Dine ændringer blev gemt korrekt', 'success');
        //     }).fail(function (response) {
        //         app.log.error("ajax.fail", { url: url, response: response });
        //         $.dcui.toast('Dine ændringer blev ikke gemt', 'error');
        //     });
        // },

        toggleInvoiceSubscription: function (event) {
            event.preventDefault();

            $.ajax({
                url: '/ModalSubscribeInvoice',
                type: 'GET'
            }).then(function (response) {
                $.dcui.modal({
                    content: response,
                    headerContent: getDictionary('webshop_myPage_settings_invoice_header')
                });
            });
        },

        departmentContact: function (event) {
            if (event) {
                event.preventDefault();
            }

            var $this = $(this),
                $container = $this.closest('.shop-department-contactinfo');

            var data = {
                customerNo: $this.attr('data-customer-no'),
                recipientEmail: $this.attr('data-consultant-email'),
                companyName: $this.attr('data-company-name')
            }

            $.ajax({
                url: '/ModalConsultantContact',
                data: data,
                type: 'GET'
            }).then(function (response) {
                $.dcui.modal({
                    content: response,
                    headerContent: getDictionary('webshop_modals_contact_header')
                });
            });

            app.log.event('Webshop', 'Department contact', 'Toggle');
        },

        departmentContactSubmit: function (event) {
            event.preventDefault();

            var $this = $(this);

            $.ajax({
                url: '/ModalConsultantContact',
                type: 'POST',
                data: $this.serialize()
            }).then(function (response) {
                var $form = $('#shop-form-consultant-contact');

                $form.replaceWith(response)
            }).catch(function () {
                $.dcui.toast('Der er sket en fejl og din forespørgsel er ikke sendt afsted.', 'error');
            });

            app.log.event('Webshop', 'Department contact', 'Submit');
        },

        handleInvoiceSubscription: function (event) {
            event.preventDefault();

            var $this = $(this);

            $.ajax({
                url: '/ModalSubscribeInvoice',
                type: 'POST',
                data: $this.serialize()
            }).then(function (response) {
                $this.replaceWith(response)
            }).catch(function () {
                $.dcui.toast(getDictionary('webshop_myPage_settings_invoice_error'), 'error');
            });
        },

        isPunchOut: function () {
            return app.helpers.cookies.read("punchout");
        },

        toggleUserSettings: function (event) {
            event.preventDefault();

            $.dcui.modal({
                headerContent: getDictionary('webshop_myPage_settings_editGenerelSettings_header'),
                delay: true
            });

            $.ajax({
                url: '/ModalMyPageUserInfo',
                type: 'GET'
            }).then(function (response) {
                $.dcui.modal.delayContent({
                    content: response
                })
            }).fail(function () {
                $.dcui.modal.close();
                $.dcui.toast(getDictionary('webshop_defaults_toast_errorText'), 'error');
            });
        },

        userSettingsHandleSubmit: function (event) {
            event.preventDefault();
            var $form = $(this),
                json = {},
                $submitbtn = $form.find('button[type="submit"]'),
                $emailsWrapper = $form.find('.shop-form-userSettings-emails'),
                $emails = $emailsWrapper.find('li'),
                $replaceObsoleteProducts = $form.find('select[name="replaceObsoleteProducts"]'),
                $ecoPctBasedOnWeight = $form.find('select[name="ecoPctBasedOnWeight"]'),
                emailValue = '';

            $submitbtn.addClass('ui-button-disabled').attr('disabled', 'disabled');

            if ($emailsWrapper.length > 0) {
                $emails.each(function (i, e) {
                    if (i != 0) {
                        emailValue += '; ';
                    }
                    emailValue += $(e).attr('data-email');
                });

                json.email = emailValue;
            }

            //if ($replaceObsoleteProducts.length > 0) {
            json.replaceObsoleteProducts = $replaceObsoleteProducts.val() == 'true';
            json.ecoPctBasedOnWeight = $ecoPctBasedOnWeight.val() == 'true';
            //}

            app.services.users.update(json).then(function (response) {
                console.log('success');
                $.dcui.toast(getDictionary('webshop_myPage_settings_saved'), 'success');
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }, function (err) {
                console.log(err);
                $submitbtn.removeClass('ui-button-disabled').removeAttr('disabled');
                $.dcui.toast(getDictionary('webshop_myPage_settings_saved_error'), 'error');
                $.dcui.modal.close();
            });
        }
    },

    views: {
        baseUrl: '/app/js/views/',

        //cacheVar: new Date().getTime(), //9
        cacheVar: 12,
        getView: function (url) {
            var view = null;
            $.ajax({
                type: 'GET',
                url: app.views.baseUrl + url + '.html' + '?cache=' + app.views.cacheVar,
                cache: true,
                async: false
            }).then(function (response) {
                view = getViewWithReplacedDictionaryItems(response);
            }).fail(function () {
                // Errorhandler
            });

            return $(view);
        },

        // appendViewData: function (view, field, data) {
        //     return view.replace('{{' + field + '}}', data);
        // }
    }
}


if (typeof (webshopSettings) != 'object') {
    app.log.error('webshopSettings undefined - ' + typeof (webshopSettings));
}

/* bindings-shopheader */
$(document).on('click', '.shop-logout', app.login.handleLogout);

/* bindings-Dashboard */
$(document).on('click', '.shop-dashboard-messages .ui-message-close-link', app.dashboard.toggleMessage);

/* bindings-Departments */
$(document).on('submit', '.mailchimp-signup', app.mailchimp.handleSubmit);

/* bindings-User */
$(document).on('click', '.shop-department-contactinfo-contact', app.user.departmentContact);

$(document).on('submit', '#shop-form-consultant-contact', app.user.departmentContactSubmit);

$(document).on('click', '#shop-mypage-toggleUserSettings', app.user.toggleUserSettings);

$(document).on('submit', '#shop-form-userSettings', app.user.userSettingsHandleSubmit);

//$(document).on('click', '#shop-mypage-toggleChangePassword', app.user.toggleChangePassword);

//$(document).on('submit', '#shop-form-userChangePassword', app.user.changePasswordHandleSubmit);

$(document).on('click', '.shop-subscribeInvoice-toggle', app.user.toggleInvoiceSubscription);

//$(document).on('submit', '#shop-form-invoiceSubscription', app.user.handleInvoiceSubscription);

//$(document).on('click', '.shop-form-userSettings-removeEmail', app.user.userSettingsEmails.removeEmail);

//$(document).on('click', '.shop-form-userSettings-addEmail', app.user.userSettingsEmails.addEmail);

// $(document).on('keydown', '.shop-form-userSettings-addEmail-value', function (event) {
//     if (event.which === 13) {
//         event.preventDefault();
//         $(this).closest('.shop-form-userSettings-addEmail-wrapper').find('.shop-form-userSettings-addEmail').click();
//     }
// });

//$(document).on('click', '.shop-form-userSettings-toggleEmail', app.user.userSettingsEmails.showUserSettingsEmail);


/* bindings-Orders */

// $(document).on('submit', '#shopOrder', function (event) { event.preventDefault(); });



/* bindings-Search eventbinding */
//$(document).on('click', '.shop-productSearch-doSubmit', app.search.submitSearchForm);

//$(document).on('click', '.shop-productSearch-toggleAdvanced', app.search.toggleAdvancedSearch);

//$(document).on('click', '#shop-productSearch-selectedProductGroups .filter', app.search.removeProductGroup);

//$(document).on('click', '#shop-productSearch-productFilters .filter', app.search.toggleFilter);

//$(document).on('click', '#shop-productSearch-suggestions .suggestion', app.search.addSearchString);

//$(document).on('keyup', '.shop-productSearch-searchText', app.search.initSuggestions);

//$(document).on('keydown', '.shop-productSearch-searchText', function (e) {
//    var $wrapper = $(this).closest('.shop-productSearch-field'),
//        $suggestions = $wrapper.find('.shop-productSearch-suggestions'),
//        key = e.which;

//    if (!$suggestions.hasClass('hidden') && $suggestions.find('.suggestion-active').length > 0 && e.which == 13) {
//        e.preventDefault();
//    }

//    if (key == 38 || key == 40) {
//        e.preventDefault();
//    }
//});

//$(document).on('click', '#shop-productSearch .shop-productSearch-clearSearch', app.search.clearSearch);


/* bindings-Search - favorites */

//$(document).on('submit', '#shop-productSearch', function (event) {
//    app.search.getResults(event, $(this));
//});

//$(document).on('click', '#shop-productSearch .shop-productSearch-favorites label', app.search.toggleFavorites);

//$(document).on('click', '#shop-productSearch .shop-productSearch-favorites .suggestion', app.search.handleSelectFavoriteList);

//$(document).on('click', '#shop-productSearch .shop-productSearch-favorites .deselect', app.search.clearSearch);

//$(document).on('click', '#shop-productList-wrapper .shop-favorites-edit', app.search.toggleFavoritesEdit);

//$(document).on('click', '#shop-productList-wrapper .shop-favorites-toggleAddProduct', app.favorites.toggleAddProduct);

//$(document).on('click', '#shop-productList-wrapper .shop-favorites-print', app.favorites.print);

//$(document).on('submit', '#shop-favorites-edit-form', app.favorites.update);

//$(document).on('click', '.shop-productSearch-favoriteListToCsv', app.favorites.toggleDownloadCsv);

//$(document).on('click', '#shop-productList-wrapper .shop-productSearch-viewType .action', app.search.changeView);

//$(document).on('change', '#shop-productList-wrapper input.sorting', app.favorites.setSortOrder);

//$(document).on('change', '#shop-productSearch-favoritelistSorting', app.search.toggleFavoritelistSort);

//$(document).on('click', '.shop-toggle-product-stdUnit', app.favorites.setUnitAsStdUnit);

//$(document).on('click', '.shop-favoriteList-removeFromActive', app.favorites.toggleCurrentFavoriteList);

/* Search eventbinding - tiles */
//$(document).on('click', '.shop-searchInspiration-container .shop-tile', app.search.selectTile);

/* Search eventbinding - results */

//$(document).on('click', '#shop-productList .shop-product-addToFavorites, #shop-productList-gallery .shop-product-addToFavorites', app.search.toggleFavoriteMenu);

//$(document).on('click', '#shop-productList .shop-product-toggleFavorite, #shop-productList-gallery .shop-product-toggleFavorite', app.favorites.toggleFavorite);

//$(document).on('click', '.shop-productList-loadMore', app.search.loadMoreProducts);



//$(document).on('change', '#shop-productList select.unit', app.search.toggleUnit);

// $().ready(function () {
//     (function () {
//         var $search = $('#shop-productSearch');
//         // BUG: Kan fejle i ældre versioner af IE (lte IE9)
//         if ($search.length > 0) {
//             // app.search.init($search);
//             /* Product footer */

//             var productListfooter = document.getElementById('shop-productList-footer'),
//                 productListFooterFixed = document.getElementById('shop-productList-footer-fixed');

//             $(window).on('scroll', function (e) {
//                 var buttonOffset = $('#shop-productList-footer').offset().top;
//                 if (!app.helpers.elementInViewport(productListfooter) && (window.pageYOffset + window.innerHeight) > buttonOffset) {
//                     try {
//                         productListFooterFixed.classList.remove('visible');
//                     }
//                     catch (ex) {

//                     }
//                     //$(productListFooterFixed).removeClass('visible');
//                 }
//                 else if (!app.helpers.elementInViewport(productListfooter) && typeof (productListFooterFixed) != 'undefined') {
//                     try {
//                         //$productListFooterFixed.addClass('visible');
//                         productListFooterFixed.classList.add('visible');
//                     }
//                     catch (ex) { }
//                 }
//                 else {
//                     try {
//                         productListFooterFixed.classList.remove('visible');
//                     }
//                     catch (ex) {
//                         // IE9 fallback
//                         $('#shop-productList-footer-fixed').removeClass('visible');
//                     }
//                 }
//             });
//         }
//     })();

//     (function () {
//         var $tabnavs = $('.tab-nav-active');
//         if ($tabnavs.length > 0) {
//             $tabnavs.each(function (i, elm) {
//                 var $tabnav = $(elm),
//                     tabnavId = $tabnav.attr('data-id'),
//                     $tabnavContentContainer = $('.tab-nav-content-container[data-id="' + tabnavId + '"]');
//             });
//         }
//     })();

//     (function () {
//         if ($('.shop-productlist-thumbs').length > 0) {
//             try {

//                 $(window).on('scroll', function () {
//                    // app.search.searchResults.loadVisibleImages();
//                 });
//             }
//             catch (ex) {
//             }
//         }
//     })();
// });

// /* Search eventbinding results tabs */

// $(document).on('click', '.tab-nav-active .tab-nav-link', function (event) {
//     event.preventDefault();

//     var $this = $(this),
//         $tabnav = $this.closest('.tab-nav'),
//         tabnavId = $tabnav.attr('data-id'),
//         tabId = $this.attr('data-tab-id'),
//         $tabContentContainer = $('.tab-nav-content-container[data-id="' + tabnavId + '"]');

//     $tabContentContainer.find('.tab-nav-content').removeClass('tab-nav-content-active');
//     $tabnav.find('.tab-nav-link').removeClass('tab-nav-link-active');

//     if (!$this.hasClass('tab-nav-link-active')) {
//         $this.addClass('tab-nav-link-active');
//         $tabContentContainer.find('.tab-nav-content').each(function (i, tabContent) {
//             var $tabContent = $(tabContent);
//             if ($tabContent.attr('data-tab-id').indexOf(tabId) > -1) {
//                 $tabContent.addClass('tab-nav-content-active');
//             }
//         });

//         //$tabContent = $tabContentContainer.find('.tab-nav-content[data-tab-id="' + tabId + '"]');
//         //$tabContent.addClass('tab-nav-content-active');
//     }
// });

/* Product-lists binding */


/* bindings-Search - Thumbs*/


//$(document).on('change', '.shop-product-thumb select.unit', app.search.toggleUnit);



// /* Search inspiration */
// $().ready(function () {
//     (function () {
//         var $container = $('.shop-searchInspiration-container');
//         if ($container.length > 0) {
//             $container.each(function (i, e) {
//                 var $this = $(this);

//                 app.inspiration.getSearchInspiration($this);
//             })
//         }
//     })();
// });






/* Campaign search */
// $().ready(function () {
//     (function () {
//         var $searches = $('.shop-campaign-productSearch');
//         if ($searches.length > 0) {
//             $searches.each(function (i, elm) {
//                 app.campaigns.init($(elm));
//             })
//         }
//     })();
// });

/* Assortment */
//$(document).on('click', '.shop-assortment-view .shop-assortment-card', app.search.assortment.toggleAssortment);

//$(document).on('click', '.shop-assortment-card ul li a', app.search.addSearchStringFromAssortmentBox);



/* Foodinfo */

//$(document).on('click', '.show-foodinfo', app.foodinfo);

//$(document).on('mouseenter', '.show-foodinfo', app.foodinfoImage.show);

//$(document).on('mouseleave', '.show-foodinfo', app.foodinfoImage.hide);

//$(document).on('click', '.show-datablad', app.info.productSheet);


/* bindings-Favorite lists */

//$(document).on('click', '#shop-favorites-delete', app.favorites.handleDeleteFavoriteList);

//$(document).on('submit', '#shop-favoritelistAddProduct', app.favorites.addProduct);

/* bindings-Frontpage */
//$(document).on('click', '#frontpage-newsWrapper-newsList .news-item', app.frontpage.slideshow.toggleSlide);

/* bindings-Offer papers */
//$(document).on('change', '#form-ipaperOffers .shop-ipaperOffers-department', app.offers.toggleDepartmentOfferPaper);

//$(document).on('click', '#form-ipaperOffers .shop-ipaperOffers-download', app.offers.downloadDepartmentOfferPaper);

/* bindings-Responsive navigation */
$(document).on('click', '.top-navigation-toggle', app.navigation.toggleResponsiveNavigation);

/* bindings-form validation */
// $(document).on('submit', '#shop-form-newCustomer', function (event) {
//     var isValid = $.dcui.form.validate(this);

//     if (!isValid) {
//         event.preventDefault();
//     }
// });

/* Responsive youtube-embed */
$().ready(function () {
    if ($.fn.fitVids) {
        $(".embed-responsive-video").fitVids();
    }
});
/* IE message */
(function () {
    var ieVersion = app.helpers.detectIe();
    if (ieVersion < 9) {
        $('body').append('<div id="shop-oldIe-message"><div class="row"><div class="columns large-12 textWrapper"><p><strong>OBS! Du bruger en gammel browser</strong></p><p>For at få den bedste mulige oplevelse i brugen af vores webshop, anbefaler vi at du opdaterer til en nyere browser.</p><p><a href="http://browsehappy.com/?locale=da_DK">Kilk her for at få hjælp</a></p></div></div></div>');
    }
})();

/* Bindings */


//$(document).on('keyup', '.shop-table-product input.amount', app.shop.productList.validateQuantity);

//$(document).on('click', '#shop-productList .shop-product-addToBasket', app.shop.addSingleProductToBasketFromList);

//$(document).on('click', '.shop-product-thumb .shop-product-addToBasket', app.shop.addSingleProductToBasket);

//$(document).on('keyup', '.shop-product-thumb .shop-product-thumb-quantity', app.shop.addSingleProductToBasketOnKey);

/* bindings-Quickshop */
// // - Quickshop
// $(document).on('change', '#shop-quickshop-productId', function () {
//     //jcd.quickshop.checkProductId(this);
//     app.shop.quickshop.lookupProductId($(this));
// });

// $(document).on('keydown', '#shop-quickshop-productId', function (e) {
//     app.shop.quickshop.setActiveLookup(e, $(this));
// });

// $(document).on('keyup', '#shop-quickshop-amount', function () {
//     app.shop.quickshop.validateAmount($(this));
// });

// $(document).on('keydown', '#shop-quickshop-unit', function (e) {
//     app.shop.quickshop.submitForm(e, $(this));
// });

// $(document).on('submit', '#shop-quickshop', function (e) {
//     e.preventDefault();
//     app.shop.quickshop.addToOrder($(this));
// });


// $(document).on('click', '.tab-quickshop', app.shop.quickshop.init);

/* Shop header */
//TODO quickfix iframe
//$(document).on('click', '.shop-header .shop-header-toggleUserMenu', app.shop.header.toggleUserMenu);

// $(document).on('click', '.toggle-organicMessage', app.shop.header.toggleOrganicMessage);

$(document).on('click', '.toggle-agreementMessage', app.shop.header.toggleAgreementMessage);


/* bindings-Shop link */
//$(document).on('click', '.shop-link-addToBasket', app.shop.handleShopLink);
//$(document).on('click', '.shop-link-addToBasket', app.shop.handlers.handlePickupProduct);

// - Orders
//$(document).on('click', '.shop-productList-collect', app.shop.collectProducts);

/* Obsolete products */
$(document).on('click', '.shop-favorites-product-delete', app.shop.obsoleteProducts.removeProduct);
$(document).on('submit', '#shop-form-delete-obsolete-item', app.shop.obsoleteProducts.removeProductSubmit);

$(document).on('click', '.shop-favorites-product-replace', app.shop.obsoleteProducts.replaceProduct);
$(document).on('submit', '#shop-form-replace-obsolete-item', app.shop.obsoleteProducts.replaceProductSubmit);

// $(document).on('click', '.orderpart-product-delete', app.shop.orders.handleRemoveProduct);

// $(document).on('change', '.shop-orderpart-product input.quantity', app.shop.orders.changeProductQuantity);

// $(document).on('keyup', '.shop-orderpart-product input.quantity', app.shop.orders.handleValidateQuantity);

// $(document).on('click', '.replace-obsolete-product-cart', app.shop.orders.handleAddAlternativeProductToCart);

//$(document).on('keyup', '.shop-orderpart-product input.quantity', app.shop.orders.validateQuantity);

// $(document).on('change', '.shop-orderpart-product select.unit', app.shop.orders.changeProductUnit);

//$(document).on('click', '.shop-orderAcceptSubmit', app.shop.orders.acceptOrder);

// $(document).on('click', '.shop-order-emptyCart', app.shop.cart.emptyCart);

//$(document).on('click', '.shop-orderpart-product-reachBroachLimit', app.shop.orders.reachBroachLimit);

/* Order history */
$(document).on('click', '.shop-orderHistoryDetails-reorder', app.shop.orderHistory.reorder);

$(document).ready(function (e) {
    $('.section').each(function (i, e) {
        var $e = $(e);
        if ($e.find(".grid-visible").length == 0) {
            var $textWrappers = $e.find('.textWrapper'),
                hasContent = $textWrappers.length == 0 ? true : false,
                hasImage = function ($elm) {
                    if ($elm.find('img').length > 0) return true;
                    else return false;
                },
                hasBackgruondImage = function ($elm) {
                    if ($elm.attr('style') && $elm.attr('style').indexOf('background-image') > -1) return true;
                    else return false;
                };

            if (hasBackgruondImage($e)) {
                hasContent = true;
            }
            else {
                $textWrappers.each(function (ei, ee) {
                    var $elm = $(ee);
                    hasBackgruondImage($elm);
                    if ($.trim($elm.text()) || hasImage($elm)) {
                        hasContent = true;
                    }
                });
            }

            if (!hasContent) {
                $(e).addClass('hidden');
            }
        }
    });
    //$('.section .textWrapper').each(function (i, elm) {
    //	var $elm = $(elm);
    //	if (!$.trim($elm.html())) {
    //		$elm.closest('.section').addClass('hidden');
    //	}
    //});


    $.dcui.form.validate.init();

});