﻿var themes = (function (modules) {
	var _private = {

	};

	modules.carousel = {
		newCarousel: function (carousel) {
			var $carousel = $(carousel);
			var $rows = $carousel.find('.carousel-row');
			var $buttonNext = $carousel.find('.carousel-next');
			var $buttonPrev = $carousel.find('.carousel-prev');
			var $indicator = $carousel.find('.carousel-indicator');
			var $indicatorBlobs = $carousel.find('.carousel-indicator-blob');

			function bindEvents () {
				$buttonNext.on('click', nextRow.bind(this));
				$buttonPrev.on('click', previousRow.bind(this));
			};

			function nextRow (e) {
				e ? e.preventDefault() : null;

				var $activeRow = $carousel.find('.carousel-row-active');

				if ($activeRow.is(':last-of-type')) {
					$rows.first().addClass('carousel-row-active');
				} else {
					$activeRow.next().addClass('carousel-row-active');
				}
				$activeRow.removeClass('carousel-row-active');

				var $activeBlob = $indicator.find('.carousel-indicator-blob-active');

				if ($activeBlob.is(':last-of-type')) {
					$indicatorBlobs.first().addClass('carousel-indicator-blob-active');
				} else {
					$activeBlob.next().addClass('carousel-indicator-blob-active');
				}
				$activeBlob.removeClass('carousel-indicator-blob-active');
			
			};

			function previousRow (e) {
				e ? e.preventDefault() : null;

				var $activeRow = $carousel.find('.carousel-row-active');

				if ($activeRow.is(':first-of-type')) {
					$rows.last().addClass('carousel-row-active');
				} else {
					$activeRow.prev().addClass('carousel-row-active');
				}
				$activeRow.removeClass('carousel-row-active');

				var $activeBlob = $indicator.find('.carousel-indicator-blob-active');

				if ($activeBlob.is(':first-of-type')) {
					$indicatorBlobs.last().addClass('carousel-indicator-blob-active');
				} else {
					$activeBlob.prev().addClass('carousel-indicator-blob-active');
				}
				$activeBlob.removeClass('carousel-indicator-blob-active');
			};

			bindEvents();
		},
		init: function () {
			if ($('.carousel').length > 0) {
				$('.carousel').each((function (i, elm) {
					this.newCarousel(elm);
				}).bind(this));
			}
		}
	};

	return modules;
}(themes || {}));

$(document).ready(function () {
	function initialize(obj) {
		if (obj.init) {
			obj.init();
		}
		for (var property in obj) {
			if (obj.hasOwnProperty(property) && !(obj[property] instanceof jQuery)) {
				initialize(obj[property]);
			}
		}
	};
	initialize(themes);
});