﻿var app = (function (modules) {
    var _private = {
        validateError: function (response) {
            /*
                Hvis der bliver lavet et ajax-kald, og der bliver navigeret væk fra siden,
                vil der blive sendt en fejl i loggen. validateError tjekker om det er en reel
                fejl, eller om den bliver smidt, fordi brugerne navigerer væk.
            */
            if (response && response.readyState === 0) {
                return false;
            }
            return true;
        }
    };

    modules.services = {
        // cart: {
        //     addToOrder: function (data) {
        //         var url = "/api/Order/AddToOrder";

        //         return $.ajax({
        //             type: "POST",
        //             url: url,
        //             data: '{"input":' + JSON.stringify(data) + '}',
        //             contentType: "application/json; charset=utf-8",
        //             dataType: "json"
        //         }).fail(function (response) {
        //             app.log.error("app.services.cart.addToOrder", response, { url: url, data: data });
        //         });
        //     },

        //     empty: function (data) {
        //         var url = `/api/Order/EmptyBasket?date=${data.date}`;
        //         return $.ajax({
        //             url: url,
        //             type: 'POST',
        //             contentType: "application/json; charset=utf-8"
        //         }).fail(function (response) {
        //             app.log.error("app.services.cart.emptyCart", response, { url: url, data: data });
        //         });
        //     }
        // },

        // products: {
        //     // getProducts: function (data) {
        //     //     var url = "/jcd/jsondata.aspx/Search";
        //     //     return $.ajax({
        //     //         type: "POST",
        //     //         url: url,
        //     //         data: '{"input":' + JSON.stringify(data) + '}',
        //     //         contentType: "application/json; charset=utf-8",
        //     //         dataType: "json",
        //     //         cache: false
        //     //     }).fail(function (response) {
        //     //         if (_private.validateError(response))
        //     //             app.log.error("app.services.products.getProducts", response, { url: url, data: data, response: response });
        //     //     });
        //     // },

        //     // getProductsById: function (data) {
        //     //     data = 'products=' + data;
        //     //     console.log(data);
        //     //     var url = "/api/product/getproducts?d=0";
        //     //     return $.ajax({
        //     //         type: 'GET',
        //     //         url: url,
        //     //         data: data,
        //     //         dataType: 'json'
        //     //     }).fail(function (response) {
        //     //         if (_private.validateError(response))
        //     //             app.log.error("app.services.products.getProductsById", response, { url: url, data: data, response: response });
        //     //     });

        //     // },

        //     getProductCategories: function () {
        //         var url = '/api/product/GetProductCategories';
        //         return $.ajax({
        //             url: url,
        //             type: 'GET',
        //             dataType: 'json'
        //         }).fail(function (response) {
        //             app.log.error("app.services.products.getProductCategories", response, { url: url, response: response });
        //         });
        //     },

        //     lookupProduct: function (data) {
        //         var url = '/api/quickShop/LookupProduct/' + data;

        //         return $.ajax({
        //             url: url,
        //             type: 'GET',
        //         }).fail(function (response) {
        //             app.log.error("app.services.products.lokupProduct", response, { url: url, response: response, data: data });
        //         });
        //     }
        // },

        favorites: {
            // addProduct: function (data, settings) {
            //     var url = '/api/FavoriteLists/AddProduct';

            //     // Valider data for checkoutId
            //     if (data && !data.checkoutId)
            //         data.checkoutId = -1;

            //     return $.ajax({
            //         url: url,
            //         type: 'POST',
            //         dataType: 'json',
            //         data: JSON.stringify(data),
            //         contentType: "application/json; charset=utf-8"
            //     }).fail(function (response) {
            //         app.log.error("app.services.favorites.addProduct", { url: url, response: response, data: data });
            //     });
            // },

            // create: function (data, settings) {
            //     var url = settings && settings.url ? settings.url : "";

            //     return $.ajax({
            //         type: "POST",
            //         url: url,
            //         data: JSON.stringify(data),
            //         contentType: "application/json; charset=utf-8",
            //         dataType: "json"
            //     }).fail(function (response) {
            //         app.log.error("app.services.favorites.create", { url: url, response: response, post: data });
            //     });
            // },

            // deleteFavoriteList: function (data) {
            //     var url = '/api/FavoriteLists/DeleteFavoriteList';

            //     return $.ajax({
            //         type: 'POST',
            //         url: url,
            //         dataType: 'json',
            //         contentType: "application/json; charset=utf-8",
            //         data: JSON.stringify(data)
            //     }).fail(function (response) {
            //         app.log.error('app.services.favorites.deleteFavoriteList', response, { url: url, data: data, response: response });
            //     });
            // },

            // getFavoriteLists: function () {
            //     var url = '/api/FavoriteLists/GetAll';

            //     return $.ajax({
            //         url: url,
            //         //data: JSON.stringify(data),
            //         type: 'GET',
            //         contentType: 'application/json; charset=utf-8'
            //     }).fail(function (response) {
            //         app.log.error("app.services.favorites.getFavoriteLists", response, { url: url, data: data });
            //     });
            // },

            // getProductFavoriteLists: function (productId) {
            //     var queryString = productId ? '?productId=' + productId : '';
            //     var url = '/api/FavoriteLists/GetAll' + queryString;

            //     return $.ajax({
            //         url: url,
            //         //data: JSON.stringify(data),
            //         type: 'GET',
            //         contentType: 'application/json; charset=utf-8'
            //     }).fail(function (response) {
            //         appErrorLog("app.services.favorites.getProductFavoriteLists", response, { url: url, data: data });
            //     });
            // },

            removeProduct: function (data, settings) {
                var url = '/api/FavoriteLists/ReplaceObsolete';

                return $.ajax({
                    url: url,
                    type: 'POST',
                    data: JSON.stringify(data),
                    contentType: "application/json; charset=utf-8"
                }).fail(function (response) {
                    app.log.error("app.services.favorites.removeProduct", { url: url, response: response, data: data });
                });
            },

            // setUnitAsStdUnit: function (data) {
            //     if (data) {
            //         var url = '/api/FavoriteLists/SetStandardUnit';

            //         return $.ajax({
            //             url: url,
            //             data: JSON.stringify(data),
            //             type: 'POST',
            //             contentType: 'application/json; charset=utf-8'
            //         }).fail(function (response) {
            //             app.log.error('app.services.favorites.setUnitAsStdUnit', response, { url: url, data: data });
            //         });
            //     }
            // },

            handleObsoleteProduct: function (data) {
                var url = '/api/FavoriteLists/ReplaceObsolete';

                return $.ajax({
                    type: 'POST',
                    url: url,
                    data: JSON.stringify(data),
                    contentType: "application/json; charset=utf-8",
                }).fail(function (response) {
                    app.log.error("app.services.favorites.handleObsoleteProduct", { url: url, response: response });
                });
            },

            // updateFavoriteList: function (data, success, fail) {
            //     var url = '/api/FavoriteLists/UpdateFavoriteList';

            //     return $.ajax({
            //         type: 'POST',
            //         url: url,
            //         data: JSON.stringify(data),
            //         contentType: "application/json; charset=utf-8",
            //         dataType: "json",
            //         cache: false
            //     }).fail(function (response) {
            //         app.log.error("app.services.favorites.updateFavoriteList", { url: url, response: response, post: data });
            //     });
            // },

            // setSortOrder: function (data) {
            //     var url = '/api/FavoriteLists/SetSortOrder';

            //     return $.ajax({
            //         url: url,
            //         data: JSON.stringify(data),
            //         type: 'POST',
            //         contentType: 'application/json; charset=utf-8'
            //     }).fail(function (response) {
            //         app.log.error('app.favorites.setSortOrder', { url: url, response: response, data: data });
            //     });
            // }
        },

        // foodinfo: {
        //     request: function (data) {
        //     	var url = data && data.url ? data.url : null;

		// 		// Hvis man har downs og bruger en gammel browser lavet af et skodfirma
        //         if (window.XDomainRequest) {
        //         	var deferred = $.Deferred();

        //         	var xdr = new XDomainRequest();
        //         	xdr.open("get", url);
        //         	xdr.onprogress = function () { };
        //         	xdr.ontimeout = function () { };

        //         	xdr.onerror = function () {
        //         		deferred.reject(xdr.responseText);
        //         	};

        //         	xdr.onload = function () {
        //         		deferred.resolve(xdr.responseText);
        //         	};

        //         	setTimeout(function () {
        //         		xdr.send();
        //         	}, 0);

        //         	return deferred.promise();
        //         } // Hvis man er et normalt velfungerende menneske og har en ordenlig browser
        //         else {
        //         	return $.ajax({
        //         		url: url,
        //         		type: 'GET'
        //         	}).fail(function (response) {
        //         		// Udkommenteret - <IE9 fylder loggen pga. CORS implementering i jquery.
        //         		//appErrorLog('appServices.foodinfo.request', response, { url: url });
        //         	});
        //         }
        //     }
        // },

        inspiration: {
            getInspirationNodesByCode: function (data) {
                if (!data) {
                    app.log.error("app.services.inspiration.getInspirationNodesByCode", null, { message: 'no customerGroup code' });
                }

                var url = '/api/customerGroups/GetInspirationNodesByCode/' + data;

                return $.ajax({
                    type: 'GET',
                    url: url,
                    dataType: 'json',
                    cache: true
                }).fail(function (response) {
                    app.log.error("app.services.inspiration.getInspirationNodesByCode", response, { url: url, response: response, data: data });
                });
            }
        },

        login: {
            // step1: function (data, settings) {
            //     var url = '/api/auth/login';

            //     if (data) {
            //         if (data.username) data.username = Base64.encode(data.username);
            //         if (data.password) data.password = Base64.encode(data.password);
            //     }
                
            //     return $.ajax({
            //         type: 'POST',
            //         url: url,
            //         data: JSON.stringify(data),
            //         contentType: "application/json; charset=utf-8",
            //         dataType: 'json'
            //     }).fail(function (response) {
            //         app.log.error("app.services.login.step1", { url: url, response: response, data: data });
            //     });
            // },

            // step2: function(data, settings) {
            //     var url = '/jcd/jsondata.aspx/Login2';

            //     if (data) {
            //         if (data.username) data.username = Base64.encode(data.username);
            //     }

            //     return $.ajax({
            //         type: 'POST',
            //         url: url,
            //         data: JSON.stringify(data),
            //         contentType: "application/json; charset=utf-8",
            //         dataType: 'json'
            //     }).fail(function (response) {
            //         app.log.error("app.services.login.step2", { url: url, response: response, data: data });
            //     });
            // },

            // dateChoice: function () {
            //     // Inactive
            // },

            // acceptanceCheck: function (login) {
            //     var url = '/api/approval/AcceptanceCheck?forLogin=' + login;

            //     return $.ajax({
            //         url: url,
            //         type: 'GET'
            //     }).fail(function (response) {
            //         app.log.error('app.services.login.acceptanceCheck', response, { url: url, response: response });
            //     });
            // },

            // acceptLogs: function (types) {
            //     var url = '/api/approval/setapprovals';
            //     console.log(types);

            //     var input = {
            //         Types: types
            //     };

            //     return $.ajax({
            //         url: url,
            //         type: 'POST',
            //         data: input
            //     }).fail(function (response) {
            //         app.log.error('app.services.users.acceptLogs', response, { url: url, response: response });
            //     });
            // },
        },

        orders: {
            complete: function (data, settings) {
                var url = settings && settings.isPunchOut ? '/jcd/jsondata.aspx/CompletePOOrder' : '/api/order/CompleteOrder';
 
                return $.ajax({
                    type: "POST",
                    url: url,
                    data: JSON.stringify(data),
                    contentType: "application/json; charset=utf-8"
                    //dataType: "json"
                }).fail(function (response) {
                    app.log.error("app.services.orders.complete", { url: url, response: response, data: data });
                });
            },

            updateOrderline: function (data) {

                var url = '/api/order/UpdateOrderLine';

                return $.ajax({
                    url: url,
                    type: 'POST',
                    data: JSON.stringify(data),
                    contentType: "application/json; charset=utf-8"
                }).fail(function (response) {
                    app.log.error("app.services.orders.updateOrderline", { url: url, response: response });
                });
            }
        },

        search: {
            getSuggestions: function (data) {
                // console.log(data);
                // var url = '/api/search/SearchSuggestions?sug=' + data.sug;

                // return $.ajax({
                //     url: url,
                //     type: 'POST',
                //     dataType: 'json',
                // }).fail(function (response) {
                //     app.log.error("app.services.search.getSuggestions", response, { url: url, data: data });
                // });
            }
        },

        users: {
            update: function (data) {
                users: {

                    console.log(data);
                    var url = '/api/customer/UpdateSettings';

                    return $.ajax({
                        url: url,
                        type: 'POST',
                        data: JSON.stringify(data),
                        contentType: "application/json; charset=utf-8"
                    }).fail(function (response) {
                        app.log.error("app.services.users.update", response, { url: url, data: data });
                    });
                }
            }
        },

        views: {
            baseUrl: '/app/js/views/',

            getView: function (url) {
                return $.ajax({
                    type: 'GET',
                    url: app.services.views.baseUrl + url + '.html' + '?version=' + app.services.views.version,
                    cache: true,
                    async: true
                }).then(function (data) {
                    data = getViewWithReplacedDictionaryItems(data);
                    return $(data);
                })
                .fail(function (response) {
                    app.log.error("appServices.views.getView", response, { url: url });
                });                
            },

            version: 13
        }
    }

    return modules;

}(app || {}));