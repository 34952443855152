﻿var app = (function (modules) {
    var _private = {

    };

    modules.exhibition = {
        handleSubmit: function (event) {
            event.preventDefault();
            var $form = $(this);

            if (confirm("Du er nu ved at tilmelde " + $('#customerName').val() + ", vil du fortsætte?")) {
                var valid = true;
                var emailError = false;
                var nameError = false;
                var noParticipantError = false;
                var noDatesError = false;
                var noDepartmentError = false;
                var company = {};

                var department = $('#departmentId').val();

                switch (department) {
                    case "Aalborg":
                        department = "ABA";
                        break;
                    case "Aarhus":
                        department = "ABE";
                        break;
                    case "Holsterbro":
                        department = "ABH";
                        break;
                    case "København":
                        department = "ABK";
                        break;
                    case "Roskilde":
                        department = "ABR";
                        break;
                    case "Ribe":
                        department = "JOG";
                        break;
                    case "Slagelse":
                        department = "ABS";
                        break;
                }

                var oneRecipient = true;
                //if(document.getElementById('recipients').checked) oneRecipient = true;

                company.branchId = department;
                company.exhibitionId = $('#exhibitionId').val();
                company.code = $('#exhibitionCode').val();
                company.id = $('#customerNumber').val();
                company.name = $('#customerName').val();
                company.address = $('#customerAddress').val();
                company.zipcode = $('#customerZipcode').val();
                company.participants = [];
                company.oneRecipient = oneRecipient;

                var responsibleEmail = $('#responsible').find('input.participantEmail');
                // Email Check - remove if email is added to participants
                if (responsibleEmail.val().length == 0 || responsibleEmail.val().search(app.exhibition.pattern) == -1) {
                    emailError = true;
                    valid = false;
                    responsibleEmail.addClass('error');
                }

                $.each($('#participantslist').find('li'), function (index, value) {
                    var participant = {};
                    var participantValid = true;

                    participant.name = $(value).find('.participantName').val();
                    participant.email = $(value).find('.participantEmail').val();
                    /*
                        //Email check
                        if(participant.name.length > 0 && (participant.email.length == 0 || participant.email.search(pattern) == -1)){ if(!document.getElementById('recipients').checked)){
                        emailError = true; valid = false; $(value).find('.participantEmail').addClass('error') }
                        }
                    */


                    //Name check
                    //if(participant.name.length == 0 && participant.email.length > 0){ 
                    if (participant.name.length == 0) {
                        nameError = true; valid = false;
                        $(value).find('.participantName').addClass('error');
                    }

                    //Empty check      
                    if (participant.name.length == 0) {
                        participantValid = false;
                    }

                    if (participantValid) company.participants.push(participant);
                });

                if (company.participants.length == 0) { valid = false; noParticipantError = true; }

                var $dateInterval = $form.find('.date-interval-wrapper');
                if ($dateInterval.length > 0) {
                    company.dates = [];
                    $.each($dateInterval.find('.date-interval-date'), function (index, value) {
                        var $field = $(value);
                        if ($field.is(':checked')) {
                            company.dates.push($field.val());
                        }
                    });

                    if (company.dates.length == 0) {
                        valid = false;
                        noDatesError = true;
                    }
                }



                var selectedDepartment = $('input[name="customerDept"]:checked').val();
                company.department = selectedDepartment;

                var $departments = $('input[name="customerDept"]');
                if ($departments.length > 0 && (selectedDepartment == null || selectedDepartment.length == 0)) {
                    valid = false;
                    noDepartmentError = true;
                    $('.customerDepartmentError').removeClass('hidden');
                    
                }
                else {
                    $('.customerDepartmentError').addClass('hidden');
                }

                var companyJSON = JSON.stringify(company);
                if (valid) {
                    $.ajax({
                        url: '/JCD/reg.ashx',
                        data: { "data": companyJSON },
                        type: 'POST'
                    }).done(function () {
                        $.dcui.modal({
                            headerContent: "Tak for din tilmelding",
                            content: "<div class='textWrapper'><p>Du er nu tilmeldt arrangementet, og vil inden længe modtage en mail med din billet.</p><p>Vi glæder os til at se dig</p></div>",
                            footerContent: "<div class='ui-form-submitwrapper'><a class='ui-button ui-button-accept ui-modal-closer'>Luk</a></div>"
                        });
                        $('#form-exhibitionRegistrationForm').closest('.section').remove();
                        //$('#thankYou').fadeIn();
                    }).fail(function (response) {
                        $.dcui.modal({
                            headerContent: "Der er sket en fejl",
                            content: "<div class='textWrapper'><p>Din tilmelding er ikke registreret. Kontakt venligst din afdeling for hjælp.</p></div>"
                        });
                        $.dcui.toast('Der er opstået en fejl', 'error');
                    });
                }
                else {
                    if (emailError) alert("Email skal udfyldes korrekt");
                    if (nameError) alert("Alle navne skal udfyldes");
                    if (noParticipantError) alert("Du skal mindst tilknytte én deltager");
                    if (noDatesError) alert("Du skal vælge mindst én dag for deltagelse");
                    if (noDepartmentError) alert("Du skal vælge hvilken afdeling du er kunde hos");
                }
            }
            return;
            function submitRegistration() {
                if (confirm("Du er nu ved at tilmelde " + $('#customerName').val() + ", vil du fortsætte?")) {
                    var valid = true;
                    var emailError = false;
                    var nameError = false;
                    var noParticipantError = false;
                    var company = {};


                    var department = $('#departmentId').val();

                    switch (department) {
                        case "Aalborg":
                            department = "ABA";
                            break;
                        case "Aarhus":
                            department = "ABE";
                            break;
                        case "Holsterbro":
                            department = "ABH";
                            break;
                        case "København":
                            department = "ABK";
                            break;
                        case "Roskilde":
                            department = "ABR";
                            break;
                        case "Ribe":
                            department = "JOG";
                            break;
                        case "Slagelse":
                            department = "ABS";
                            break;
                    }

                    var oneRecipient = true;
                    //if(document.getElementById('recipients').checked) oneRecipient = true;

                    company.branchId = department;
                    company.exhibitionId = $('#exhibitionId').val();
                    company.id = $('#customerNumber').val();
                    company.name = $('#customerName').val();
                    company.address = $('#customerAddress').val();
                    company.zipcode = $('#customerZipcode').val();
                    company.participants = [];
                    company.oneRecipient = oneRecipient;

                    var responsibleEmail = $('#responsible').find('input.participantEmail');

                    // Email Check - remove if email is added to participants
                    if (responsibleEmail.val().length == 0 || responsibleEmail.val().search(pattern) == -1) {
                        emailError = true; valid = false; responsibleEmail.addClass('error');
                    }

                    $.each($('#participantslist').find('li'), function (index, value) {
                        var participant = {};
                        var participantValid = true;

                        participant.name = $(value).find('.participantName').val();
                        participant.email = $(value).find('.participantEmail').val();
                        /*
                            //Email check
                            if(participant.name.length > 0 && (participant.email.length == 0 || participant.email.search(pattern) == -1)){ if(!document.getElementById('recipients').checked)){
                            emailError = true; valid = false; $(value).find('.participantEmail').addClass('error') }
                            }
                        */


                        //Name check
                        //if(participant.name.length == 0 && participant.email.length > 0){ 
                        if (participant.name.length == 0) {
                            nameError = true; valid = false; $(value).find('.participantName').addClass('error')
                        }

                        //Empty check      
                        if (participant.name.length == 0) {
                            participantValid = false;
                        }

                        if (participantValid) company.participants.push(participant);
                    });

                    if (company.participants.length == 0) { valid = false; noParticipantError = true; }

                    if (valid) {

                        var companyJSON = JSON.stringify(company);

                        $.post('/reg.ashx', { "data": companyJSON }, function (data) {
                            if (data.success == 1) {
                                $('#exhibitionRegistrationForm').slideUp();
                                $('#thankYou').fadeIn();
                            }
                        }, 'json');

                    }
                    else {
                        if (emailError) alert("Email skal udfyldes korrekt");
                        if (nameError) alert("Alle navne skal udfyldes");
                        if (noParticipantError) alert("Du skal mindst tilknytte én deltager");
                    }
                }
            }
        },

        pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

        removeParticipant: function (event) {
            event.preventDefault();

            var elm = $(this).closest('li');
            $(elm).slideUp('fast', function () {
                $(elm).remove();
            });
        },

        toggleAddParticipant: function (event) {
            event.preventDefault();

            var emailWrapperClass = "emailWrapper";
            if (document.getElementById('recipients').checked) emailWrapperClass += " hidden";

            // With Email   var participantItem = '<li class="participant hidden"><label class="participant">Navn på deltager:</label><input class="participantName" type="text"/><div class="' + emailWrapperClass + '"><label class="email">E-mail:</label><input class="participantEmail" type="text"/></div><a class="removeParticipant" Title="Fjern linje" href="#"><img src="/images/delete.png" alt="Fjern deltager"/></a><div class="clear"></div></li>';      
            var participantItem = '<li class="participant hidden"><div class="row"><div class="columns large-6"><div class="ui-form-fieldwrapper"><label class="participant">Navn på deltager:</label><input class="participantName" type="text"/></div><div class="' + emailWrapperClass + '"></div></div><div class="columns large-6"><a class="removeParticipant ui-button ui-button-small" Title="Fjern linje" href="#">Fjern deltager</a></div></div></li>';

            $('#participantslist').append(participantItem);
            $('#participantslist').find('li.hidden').slideDown('fast', function () { $(this).removeClass('hidden') });
        },

        toggleParticipantDates: function (event, ignore) {
            console.log(event, ignore);

            var $field = $('.date-interval-all-dates');

            if ($field.is(':checked')) {
                $field.closest('form').find('.date-interval-date').prop('checked', true);
            }
            else {
                $field.closest('form').find('.date-interval-date').prop('checked', false);
            }
        },

        toggleSingleParticipantDate: function (event) {
            var $field = $(this);
            console.log('wat');
            if (!$field.is(':checked')) {
                console.log('wat');
                $('.date-interval-all-dates').prop('checked', false);
            }
        },

        init: function () {
            modules.exhibition.toggleParticipantDates();
        }
    }

    return modules;
}(app || {}));

/* Exhibition */
$(document).on('submit', '#form-exhibitionRegistrationForm', app.exhibition.handleSubmit);

$(document).on('click', '#addParticipant', app.exhibition.toggleAddParticipant);

$(document).on('click', 'a.removeParticipant', app.exhibition.removeParticipant);

//$(document).on('change', '.date-interval-all-dates', app.exhibition.toggleParticipantDates);

$(document).on('change', '.date-interval-date', app.exhibition.toggleSingleParticipantDate);

$(document).ready(function () {
    //app.exhibition.init();
});