﻿var app = (function (modules) {
    var _private = {

    };

    modules.admin = {
        toggleCreateNews: function () {
            app.services.views.getView('shopAdminNewsCreate').then(function (response) {
                console.log(response);

                $.dcui.modal({
                    content: response,
                    headerContent: 'Opret nyhed'
                });
            });
                         
        }
    }

    return modules;
}(app || {}));