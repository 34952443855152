﻿/*
    http://www.google.com/design/spec/patterns/search.html#search-in-app-search
*/

var global = {
    tracking: {
        event: function (category, action, label) {
            var trackArr = ['_trackEvent'];

            if (category) trackArr.push(category);
            if (action) trackArr.push(action);
            if (label) trackArr.push(label);

            if (typeof _gaq != 'undefined') {
                _gaq.push(trackArr);
            }
        },

        pageView: function (url) {
            if (typeof _gaq != 'undefined' && url) {
                _gaq.push(['_trackPageview', url]);
            }
        }
    },
    ui: {}
};
(function ($) {
    /*
        UI library for dansk-cater, som skal bruges på webapps samt webshops.
        Indeholder:
        - Slider
        - Modal
        - Loader
        - Message
        - Toast
        - Menu 
        - Form validation
        - Cookiemessage
        - Cookies
        - Pop button
    */

    $.dcui = function () {
        
    };

    // Slider
    $.dcui.slider = function (slider) {
        if (slider) {
            var $slider = $(slider),
                $slides = $slider.find('.ui-slide'),
                $slidesNav = $slider.find('.ui-slider-nav-item'),
                $btnNext = $slider.find('.ui-slider-btnnext'),
                $btnPrev = $slider.find('.ui-slider-btnprev'),
                nextIndex = 1,
                prevIndex = $slides.length - 1,
                timer = 5000;

            var nextSlide = function (index) {
                //console.log('nextslide', $slides.length);



                console.log(nextIndex, $slides.length);
                console.log({ index: index, next: nextIndex, prev: prevIndex });

                nextIndex = index >= $slides.length - 1 ? 0 : index + 1;
                prevIndex = index == 0 ? $slides.length - 1 : index - 1;


                //if (index == $slides.length-1) {
                //    nextIndex = 0;
                //}
                //else {
                //    nextIndex = index++;
                //}

                //if (index == 0) {
                //    prevIndex = $slides.length;
                //}
                //else {
                //    prevIndex = index--;
                //} index == $slides.length - 1
                console.log({ index: index, next: nextIndex, prev: prevIndex });


                //if (nextIndex == $slides.length) {
                //    console.log('set to 0');
                //    nextIndex = 0;
                //}
                //if (index = 0) {
                //    prevIndex = $slides.length;
                //}

                //console.log('init index', index);

                var $current = $slider.find('.ui-slide-active'),
                    $next = $($slides[index]),
                    $nextNav = $($slidesNav[index]),
                    imgPath = $next.attr('data-image');

                $next.addClass('ui-slide-next');
                $current.addClass('ui-slide-currentToInactive');

                //return;

                $.ajax({
                    url: imgPath,
                    type: 'GET'
                }).done(function () {
                    $next.css({
                        'background-image': 'url("' + imgPath + '")'
                    });
                    //setTimeout(function () {
                        $next.addClass('ui-slide-nextToActive');
                        setTimeout(function () {
                            $slidesNav.removeClass('news-item-active');
                            $nextNav.addClass('news-item-active');
                            $current.removeClass('ui-slide-active ui-slide-currentToInactive');
                            $next.addClass('ui-slide-active').removeClass('ui-slide-nextToActive ui-slide-next');
                        }, 400);
                    //}, 100);
                }).fail(function () {

                });
            };

            $slides.each(function (i, slide) {
                var $slide = $(slide);

                if (i == 0) {
                    $slide.addClass('ui-slide-first ui-slide-active');
                    $slider.css('height', $slide.height());
                }


                //console.log($slide);

                //$slide.on('click', function () {
                //    //alert('Wat!' + i);
                //});
            });

            if ($slidesNav.length > 0) {
                $slidesNav.each(function (i, navItem) {
                    var $navItem = $(navItem);
                    $navItem.attr('data-pos', i);
                    $navItem.on('click', function () {
                        if (!$navItem.hasClass('news-item-active')) {
                            nextSlide(i);
                        }
                        clearInterval(slideTimer);
                    })
                });
            }

            $btnNext.on('click', function (e) {
                e.preventDefault();
                nextSlide(nextIndex);
                clearInterval(slideTimer);
            });
            $btnPrev.on('click', function (e) {
                e.preventDefault();
                nextSlide(prevIndex);
                clearInterval(slideTimer);
            });

            var slideTimer = setInterval(function () {
                nextSlide(nextIndex);
            }, timer);
        }
    };

    $.dcui.sliders = [];

    /* Init slider */
    $().ready(function () {

        var $sliders = $('.ui-slider');
        console.log($sliders.length);
        if ($sliders.length > 0) {
            $sliders.each(function (i) {
                $.dcui.slider(this);
            });
        }
    });
     
    // Modal
    $.dcui.modal = function (settings) {
        var $body = $('body'),
        $modal = $(document.createElement('div')).addClass('ui-modal').html('<div class="ui-modal-container"><a href="#" class="ui-modal-close-link ui-modal-closer"><i class="mdi mdi-close-circle-outline"></i></a><div class="ui-modal-content"></div></div>'),
        $modalContainer = $modal.find('.ui-modal-container'),
        $modalContent = $modal.find('.ui-modal-content'),
        $overlay = $(document.createElement('div')).addClass('ui-modal-overlay'),
        $existingModal = $('.ui-modal'),
        content = '',
        footerContent = '',
        headerContent = '',
        isConfirm = false,
        acceptText = 'Ok',
        acceptClass = '',
        cancelText = getDictionary("webshop_modals_general_CancelClose"),
        delay = false,
        disableClose = false,
        disableOverlayClose = false,
        modalWidth = '500',
        modalHeight = '500';

        /*  Settings options:
            -------------------------------------------------
            settings.content        string
            settings.disableClose   bool
            settings.onInit         Function inited before $modal is appended to body
            settings.iframe         {src: ''}
            settings.confirm        {onAccept: fn}
            settings.headerContent  string
            settings.acceptText     string
            settings.acceptClass    string
            settings.cancelText     string
            settings.delay          bool
        */

        if (settings) {
            if (settings.delay) {
                delay = true;
            }

            if (settings.width) {
                $modalContainer.css('max-width', settings.width);
            }

            if (settings.height) {
                $modalContainer.css('height', settings.height);
            }

            if (settings.confirm) {
                isConfirm = true;

                if (settings.confirm.onConfirm) {
                    $(document).on('click.ui-modalAccept', '.ui-modal-confirm-accept', function () {
                        settings.confirm.onConfirm();
                        $(document).off('click', '.ui-modal-confirm-accept');
                        $.dcui.modal.onClose = function () { };
                    });
                }

                if (settings.confirm.onClose) {
                    $.dcui.modal.onClose = function () {
                        settings.confirm.onClose();
                        $(document).off('click', '.ui-modal-confirm-accept');
                        $.dcui.modal.onClose = function () { };
                    }
                }
            }

            if (settings.onBeforeClose) {
                $.dcui.modal.onBeforeClose = function () {
                    settings.onBeforeClose();
                    $.dcui.modal.onBeforeClose = function () { };
                }
            }

            if (settings.headerContent) {
                headerContent = '<h3 class="ui-modal-header">' + settings.headerContent + '</h3>';
                content += headerContent;
            }

            if (settings.content)
                content += settings.content;

            if (isConfirm) {
                if (settings.acceptText)
                    acceptText = settings.acceptText;

                if (settings.acceptClass)
                    acceptClass = ' ' + settings.acceptClass;

                if (settings.cancelText)
                    cancelText = settings.cancelText;

                footerContent += '<div class="ui-modal-confirm-wrapper"><a href="#" class="ui-button ui-button-secondary ui-modal-closer">' + cancelText + '</a><a href="#" class="ui-button ui-modal-confirm-accept' + acceptClass + '">' + acceptText + '</a></div>';
            }

            if (settings.iframe) {
                var iframeSrc = settings.iframe.src ? settings.iframe.src : '/';
                content = '<iframe class="ui-modal-content-iframe" style="width:100%; height: 100%;" src="' + iframeSrc + '" frameborder="0"><p>' + getDictionary('webshop_errors_iframeSupport') + '</p></iframe>';
            }

            if (settings.disableClose === true)
                disableClose = true;

            if (settings.disableOverlayClose === true)
                $overlay.addClass('ui-modal-overlay-disableClose');

            if (settings.footerContent)
                footerContent += settings.footerContent;

            //if (settings.onClose)
            //    onOpen = settings.onClose;
        }

        if ($existingModal.length > 0) {
            $existingModal.addClass('ui-modal-to-close');
            $.dcui.modal.close();
        }

        if (delay) {
            content += '<div class="ui-modal-delayContent"><div class="ui-modal-spinner" style=""></div></div>';
        }

        if (footerContent) {
            content += '<div class="ui-modal-footer' + (settings.footerContentDevider === true ? " ui-modal-devider" : "") + '">' + footerContent + '</div>';
        }

        $modalContent.html(content);
        $body.prepend($overlay).prepend($modal);

        $.dcui.modal.activeModal = [
            $overlay, $modal
        ];

        $modal.hide().show(0);
        
        $modal.addClass('ui-modal-visible ui-modal-to-close');

        if (settings.onInit)
            settings.onInit();

        if (disableClose && isConfirm) {
            if (disableClose) {
                $modal.find('.ui-modal-close-link').remove();
            }

            $(document).on('click.ui-modalClose', function (e) {
                var $target = $(e.target);

                if ($target.closest('.ui-modal-closer').length > 0) {
                    e.preventDefault();
                    $.dcui.modal.close();
                    $(document).off('click.ui-modalClose');
                    $(document).off('click.ui-modalAccept');
                }
            });
        }
        else if (disableClose) {
            $modal.find('.ui-modal-close-link').remove();
        }
        else {
            $(document).on('click.ui-modalClose', function (e) {
                var $target = $(e.target);

                if (($target.closest('.ui-modal-container').length == 0 || $target.closest('.ui-modal-closer').length > 0 || $target.hasClass('ui-modal-closer')) && $target.closest('.ui-datepicker-header').length == 0) {
                    e.preventDefault();
                    $.dcui.modal.close();
                    $(document).off('click.ui-modalClose');
                    $(document).off('click.ui-modalAccept');
                }
            });
        }
    }

    $.dcui.modal.activeModal = null;

    $.dcui.modal.close = function () {
        var $targetModal = $('.ui-modal-to-close'),
            $targetOverlay = $('.ui-modal-overlay');

        if (!$targetOverlay.hasClass('ui-modal-overlay-disableClose')) {
            $targetOverlay.addClass('ui-modal-overlay-close');
        
            setTimeout(function () {
                $targetOverlay.remove();
            }, 200);
        }

        $.dcui.modal.onBeforeClose();

        $targetModal.addClass('ui-modal-close');
        setTimeout(function () {
            $targetModal.remove();
            $.dcui.modal.onClose();
        }, 600);

        if ($.dcui.modal.activeModal != null) {
            $.dcui.modal.activeModal = null;
        }
    }

    $.dcui.modal.onBeforeClose = function () {
        console.log('modal.onBeforeClose');
        return;
    };

    $.dcui.modal.onClose = function () {
        return;
    }

    $.dcui.modal.delayContent = function (settings) {
        if (settings && $.dcui.modal.activeModal != null) {
            var $modal = $.dcui.modal.activeModal[1],
                $contentWrapper = $modal.find('.ui-modal-delayContent');

            if (settings.content) {
                $contentWrapper.html(settings.content);
            }
            if (settings.onInit) {
                settings.onInit();
            }
        }
    };

    // Loader
    $.dcui.loader = function (settings) {
        if (!$.dcui.loader.activeLoader) {

            var $body = $('body'),
                $existingLoader = $('.ui-loader'),
                $existingOverlay = $('.ui-modal-overlay'),
                exists = $existingLoader.length > 0,
                content = settings && settings.content ? settings.content : getDictionary('webshop_defaults_loaderContent'),
                $overlay = $(document.createElement('div')).addClass('ui-modal-overlay ui-modal-overlay-disableClose'),
                $loader = null;

            if (!exists) {
                $loader = $(document.createElement('div')).addClass('ui-loader').html('<div class="ui-loader-container ui-loader-position"><div class="ui-loader-content"><div class="ui-loader-spinner"></div><span class="text">' + content + '</span></div></div>');
                $body.prepend($overlay).prepend($loader);
            }
            else {
                $loader = $existingLoader;
                $loader.find('.text').html(content);
                $body.prepend($overlay).prepend($loader);
            }

            setTimeout(function () {
                $loader.addClass('ui-loader-visible').removeClass('ui-loader-close');
                $overlay.addClass('ui-modal-overlay-visible');
            }, 100);

            $.dcui.loader.activeLoader = {
                $loader: $loader,
                $overlay: $overlay
            };
        }
    };

    $.dcui.loader.activeLoader = null;

    $.dcui.loader.stop = function () {
        var activeLoader = $.dcui.loader.activeLoader;
        if (activeLoader) {
            var $targetOverlay = activeLoader.$overlay,
                $targetLoader = activeLoader.$loader;

            $targetOverlay.addClass('ui-loader-overlay-close');
            setTimeout(function () {
                $targetOverlay.remove();
            }, 200);

            $targetLoader.addClass('ui-loader-close');
            setTimeout(function () {
            	//$targetLoader.remove();
            	$targetLoader.removeClass('ui-loader-visible');
            }, 300);

            $.dcui.loader.activeLoader = null;
        }
    };

    $.dcui.loader.error = function () {

    };
    
    // Messages
    $.dcui.message = function () { };

    $.dcui.message.action = function (event) {
        if (event) {
            event.preventDefault();
        }

        var $this = $(this),
            $message = $this.closest('.ui-message'),
            $content = $message.find('.ui-message-popup-content');

        $.dcui.modal({
            headerContent: $message.find('.ui-message-header').html(),
            content: $content.html()
        });
    }

    $.dcui.message.current = null;

    $.dcui.message.close = function (event) {
        event.preventDefault();
        event.stopPropagation();

        var $this = $(this),
            $messageWrapper = $this.closest('.ui-message-wrapper'),
            $message = $this.closest('.ui-message'),
            messageId = $message.attr('data-message-id'),
            $removeElm = null;

        if ($messageWrapper.length > 0) {
            $messageWrapper.addClass('ui-message-fadeout');
            $removeElm = $messageWrapper;
        }
        else {
            $message.addClass('ui-message-fadeout');
            $removeElm = $message;
        }

        setTimeout(function () {
            $removeElm.remove();
        }, 300);

        var cookieValues = $.dcui.cookies.read('ui-messages-accepted');

        if (!cookieValues) {
            cookieValues = '';
        }
        else {
            cookieValues += ','
        }

        cookieValues += messageId;
        $.dcui.cookies.create('ui-messages-accepted', cookieValues, 364);
    }

    $(document).on('click', '.ui-message-popup', $.dcui.message.action);

    $(document).on('click', '.ui-message-close-link', $.dcui.message.close);

    // Toast messages
    $.dcui.toast = function (text, type, label, settings) {
        var $body = $('body'),
        $toast = $(document.createElement('div')).addClass('ui-toast').html('<div class="ui-toast-content"><div class="ui-toast-label"></div><div class="ui-toast-text"></div></div>'),
        toastType = type ? type : 'undefined',
        toastClass = null,
        toastLabel = null;
        $label = $toast.find('.ui-toast-label'),
        $existingToast = $('.ui-toast'),
        toastCloseCurrent = function () {
            var $targetToast = $('.ui-toast-to-close');

            $targetToast.addClass('ui-toast-close');
            setTimeout(function () {
                $targetToast.remove();
            }, 600);
        };

        if ($existingToast.length > 0) {
            $existingToast.addClass('ui-toast-to-close');
            toastCloseCurrent();
        }

        switch (toastType) {
            case 'success':
                toastClass = 'ui-toast-success';
                break;

            case 'error':
                toastLabel = getDictionary('webshop_defaults_toast_errorLabel');
                toastClass = 'ui-toast-error';
                break;

            default:
                toastLabel = getDictionary('webshop_defaults_toast_defaultLabel');
                break;
        }

        if (label || toastLabel) {
            toastLabel = label ? label : toastLabel;
            $label.html(toastLabel);
        }
        else {
            $label.remove();
        }

        if (toastClass)
            $toast.addClass(toastClass);


        $toast.find('.ui-toast-text').html(text);

        if (!type && !label) {
            $label.remove();
        }

        $body.prepend($toast);
        $toast.hide().show(0);

        $toast.addClass('ui-toast-visible');
        setTimeout(function () {
            $toast.addClass('ui-toast-to-close');
            toastCloseCurrent();
        }, settings && settings.duration ? settings.duration : 3000);
    };

    // Menus
    $.dcui.menu = function (items, $container, settings) {
        var init = function () {
            console.log('init');
        }
        var fastClose = false;
        var enableDelete = false;

        if (settings) {
            if (settings.onInit) {
                init = settings.onInit;
            }

            if (settings.fastClose) {
                fastClose = settings.fastClose;
            }

            if (settings.enableDelete) {
                enableDelete = true;
            }

        }
        $container.addClass('ui-menu-opener');

        //item format:
        //-------------------------
        //item = {};
        //item.icon = 'classname';
        //item.name = string;
        //item.id = string;
        //item.active = true/false;
        //item.class = string;

        var $menu = $(document.createElement('div')).attr({
            'class': 'ui-menu'
        }),
            content = '';

        if ($.dcui.menu.exsisting.length > 0) {
            for (var i = 0; i < $.dcui.menu.exsisting.length; i++) {
                $.dcui.menu.exsisting[i].remove();
            }
            $.dcui.menu.exsisting = [];
        }

        console.log(settings, 'settings');

        content += '<ul>';
        if (items) {
            for (var i = 0; i < items.length; i++) {
                var item = items[i],
                    cssClass = '',
                    activeClass = item.active === true ? 'ui-menu-item-active' : '';

                cssClass += activeClass;
                if (item.cssClass) {
                    cssClass += ' ' + item.cssClass;
                }

                content += '<li data-id="' + item.id + '" data-name="' + item.name + '" class="' + cssClass + '">';
                if (item.icon) {
                    content += '<span class="icon-' + item.icon + '"></span>';
                }

                if (item.href) {
                    content += '<a href="' + item.href + '">' + item.name + '</a>';
                }
                else {
                    content += item.name;
                }

                if (enableDelete) {
                    content += '<span class="icon-delete ui-menu-delete"></span>';
                }
                content += '</li>';
            }
        }
        content += '</ul>';

        if (settings && settings.footer) {
            content += '<div class="ui-menu-footer">' + settings.footer + '</div>'
        }

        $menu.html(content);

        if ($container) {
            $container.after($menu);
            $menu.hide().show(0);
            $menu.addClass('ui-menu-active');
            $.dcui.menu.current = $menu;
            $.dcui.menu.exsisting.push($menu);
            init();
        }
        setTimeout(function () {
            $(document).on('click.ui-menu', function (e) {
                var $target = $(e.target);

                if (($target.closest('.ui-menu').length == 0 || $target.closest('.ui-menu-opener').length == 1) || fastClose) {
                    console.log('removing');
                    $menu.remove();
                    $.dcui.menu.exsisting = [];
                    $(document).off('click.ui-menu');
                    e.stopPropagation();
                }
            });
        }, 100);
    };

    $.dcui.menu.current = null;

    $.dcui.menu.exsisting = [];

    // Forms
    $.dcui.form = function () { };

    $.dcui.form.validate = function (form) {
        //<span class="ui-form-field-error" style="
        //font-size: .7em;
        //display: block;
        //border-top: 3px solid #AF003A;
        ///* background: #fbeff3; */
        //color: #AF003A;
        //padding: 5px 0;
        //">Brugernavn skal udfyldes</span>

        var $form = $(form),
            valid = true,
            errors = [
                {
                    name: 'required',
                    text: ' skal udfyldes'
                }
            ];

        // Clear errors
        $.dcui.form.validate.isValid = true;
        $form.find('.ui-form-fielderror').removeClass('ui-form-fielderror');
        $form.find('.ui-form-fielderror-message').remove();

        $form.find('input[data-required]').each(function (i, field) {
            $.dcui.form.validate.field($(field));
        });

        $form.find('select[data-required]').each(function (i, field) {
            $.dcui.form.validate.select($(field));
        });



        //return false;

        return $.dcui.form.validate.isValid;
    };

    $.dcui.form.validate.isValid = true;

    $.dcui.form.validate.field = function ($elm) {
        var valid = true;

        if (typeof ($elm.attr('data-required')) != 'undefined') {
            if ($elm.val().length == 0) {
                valid = false;
                $.dcui.form.validate.isValid = false;
                $elm.addClass('ui-form-fielderror');
                $elm.after('<span class="ui-form-fielderror-message">' + getDictionary('webshop_form_fieldError_Required') + '</span>')
            }
        }
        return valid;
    };

    $.dcui.form.validate.select = function ($elm) {
        var valid = true;

        if (typeof ($elm.attr('data-required')) != 'undefined') {
            if ($elm.val() == 0) {
                valid = false;
                $.dcui.form.validate.isValid = false;
                $elm.addClass('ui-form-fielderror');
                $elm.after('<span class="ui-form-fielderror-message">' + getDictionary('webshop_form_fieldError_Required') + '</span>')
            }
        }
        return valid;
    };

    $.dcui.form.validate.init = function () {
        //$('form.validate').each(function (i, form) {

        //});
        console.log('inited');
        //
        $('input[data-required]').on('keyup', function (event) {
            var $this = $(this),
                $parent = $this.closest('div');

            if ($this.val().length > 0 && $this.hasClass('ui-form-fielderror')) {
                var $errorMsg = $parent.find('.ui-form-fielderror-message');

                $this.removeClass('ui-form-fielderror');
                $errorMsg.remove();
            }
        });


    }

    // Cookiemessage
    $.dcui.cookieMessage = function () { };

    $.dcui.cookieMessage.accept = function (event) {
        if (event) {
            event.preventDefault();
        }

        var $this = $(this),
            $message = $this.closest('.ui-cookieMessage');

        var date = new Date();
        date.setTime(date.getTime() + (364 * 24 * 60 * 60 * 1000));
        var expires = date.toGMTString();
        document.cookie = 'cookieAccepted=true;expires=' + expires + '; path=/';

        $message.addClass('ui-cookieMessage-hide');

        setTimeout(function () {
            $message.remove();
        }, 500);
    };

    $(document).on('click', '#ui-cookieMessage-accept', $.dcui.cookieMessage.accept);

    // Cookies
    $.dcui.cookies = {
        create: function(name, value, days) {
            var expires;
            if (days) {
                var date = new Date();
                date.setTime(date.getTime()+(days*24*60*60*1000));
                expires = "; expires="+date.toGMTString();
            }
            else {
                expires = "";
            }
            document.cookie = name+"="+value+expires+"; path=/";
        },

        read: function(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1,c.length);
                }
                if (c.indexOf(nameEQ) === 0) {
                    return c.substring(nameEQ.length,c.length);
                }
            }
            return null;
        }
    };

    // Tooltips
    $.dcui.tooltip = function (settings) {
        if (settings) {
            var $wrapper = settings.$wrapper ? settings.$wrapper : null,
                status = settings.status ? settings.status : 'error',
                text = settings.text ? settings.text : '-',
                $tooltip = $(document.createElement('div'));

            $tooltip.addClass('ui-tooltip ui-tooltip-' + status);
            $tooltip.html('<div class="ui-tooltip-content">' + text + ' <a class="ui-tooltip-close"><span class="icon-close"></span></a></div>');

            if ($wrapper) {
                $wrapper.find('.ui-tooltip').remove();

                $wrapper.append($tooltip);

                setTimeout(function () {
                    $tooltip.addClass('ui-tooltip-remove');
                    setTimeout(function () {
                        try {
                            $tooltip.remove();
                        }
                        catch (ex) {
                            
                        }
                    }, 200);
                }, 4000);
            }
        }
        else {
            return null;
        }
    }

    $(document).on('click', '.ui-tooltip-close', function () {
        var $this = $(this),
            $tooltip = $this.closest('.ui-tooltip');

        $tooltip.remove();
    });
    
    // Pop button
    $.dcui.popButton = function (settings, $container) {
        
        var fastClose = false,
            text = "";

        if (settings) {
            if (settings.fastClose) fastClose = settings.fastClose;
            if (settings.text) text = settings.text;
        }

        if (text.length > 0) {
            $container.addClass('ui-button-opener');

            var $button = $(document.createElement('a')).attr({
                'class': 'ui-popButton' + (settings && settings.cssClass ? ' shop-toggle-product-stdUnit' : ''),
                'href': '#'
            }).html(text);

            if ($.dcui.popButton.exsisting.length > 0) {
                for (var i = 0; i < $.dcui.popButton.exsisting.length; i++) {
                    $.dcui.popButton.exsisting[i].remove();
                }
                $.dcui.popButton.exsisting = [];
            }

            if ($container) {
                $container.after($button);
                $button.hide().show(0);
                $button.addClass('ui-popButton-active');
                $.dcui.popButton.current = $button;
                $.dcui.popButton.exsisting.push($button);
            }

            setTimeout(function () {
                $(document).on('click.ui-popButton', function (e) {
                    var $target = $(e.target);

                    if (($target.closest('.ui-popButton').length == 0 || $target.closest('.ui-popButton-opener').length == 1) || fastClose) {
                        console.log('removing');
                        $button.remove();
                        $.dcui.popButton.exsisting = [];
                        $(document).off('click.ui-popButton');
                        e.stopPropagation();
                    }
                });
            }, 100);
        }
        else {
            console.log('ui-popButton', 'notext');
        }
    }

    $.dcui.popButton.current = null;

    $.dcui.popButton.exsisting = [];
})(jQuery);
