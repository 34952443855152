﻿window.appErrorLog = function (message, stacktrace, error, errorstatus) {
    // var readCookie = function(name) {
    //     var nameEQ = name + "=";
    //     var ca = document.cookie.split(';');
    //     for(var i=0;i < ca.length;i++) {
    //         var c = ca[i];
    //         while (c.charAt(0) === ' ') {
    //             c = c.substring(1,c.length);
    //         }
    //         if (c.indexOf(nameEQ) === 0) {
    //             return c.substring(nameEQ.length,c.length);
    //         }
    //     }
    //     return null;
    // }

    var timestamp = webshopSettings && webshopSettings.timestamp ? webshopSettings.timestamp : null;

    var json = {
        //message: message,
        stacktrace: stacktrace ? JSON.stringify(stacktrace) : "",
        //url: window.location.href,
        //browser: navigator.userAgent,
        initTimestamp: timestamp,
        data: error ? JSON.stringify(error) : "",
        //userid: readCookie('shopCustomerId') ? readCookie('shopCustomerId') : "Ingen kundenr.",
        //cookies: document.cookie,
        errorstatus: errorstatus ? errorstatus : "error"
    }

    // $.ajax({
    //     type: 'POST',
    //     url: '/api/eventlog/log',
    //     data: json
    // });
}