﻿/*
    Add class to header on scroll
*/
$(window).on('scroll', function () {
    var $floatingHeader = $('#shop-floatingHeader'),
        breakpoint = 100;

    if (window.pageYOffset >= breakpoint && !$floatingHeader.hasClass('shop-floatingHeader-visible')) {
        $floatingHeader.addClass('shop-floatingHeader-visible');
    }
    else if (window.pageYOffset < breakpoint && $floatingHeader.hasClass('shop-floatingHeader-visible')) {
        $floatingHeader.removeClass('shop-floatingHeader-visible');
    }
});

var app = (function (modules) {
    var _private = {

    }

    modules.sections = {
        hideSection: function (event) {
            event.preventDefault();

            var $this = $(this),
                $section = $this.closest('.section'),
                hideableName = $section.attr('data-rowhideable'),
                isHideable = hideableName ? true : false;

            if (isHideable) {
                $section.addClass('section-toHide');
                $.cookie('ui-section-' + hideableName, true, { expires: 30, path: '/' });

                setTimeout(function () {
                    $section.remove();
                }, 200);
            }
        }
    }

    return modules;
}(app || {}));

$(document).on('click', '.section-closer', app.sections.hideSection);